import React, { Component } from 'react';

//SASS
import './sass/Card.scss';

import MasterCard from '../../assets/cards/mastercard.svg'
import Visa from '../../assets/cards/visa.svg'
import AMEX from '../../assets/cards/amex.svg'
import Diners from '../../assets/cards/diners.svg'
import Discover from '../../assets/cards/discover.svg'
import JCB from '../../assets/cards/jcb.svg'
import defaultCard from '../../assets/cards/null.svg';

import { MdDelete } from "react-icons/md";

import axios from 'axios';

//Redux
import Store from '../../redux/store';
import { UPDATE_CARDS } from '../../redux/actions';

import classnames from 'classnames';

import showResponse from '../../UI_Elements/Response/showResponse';


class Card extends Component{
   constructor(){
      super();

      this.deleteCard = this.deleteCard.bind(this);
      this.setDefaultMethod = this.setDefaultMethod.bind(this);
   }

   deleteCard(){
      const { cardID } = this.props;

      if( window.confirm('Are you sure you want delete this payment method?') ){
         axios.delete(`/api/manage-card/${cardID}`)
            .then(res => {
               Store.dispatch( UPDATE_CARDS(res.data) )
               console.log(res)
            })
            .catch(err => {
               console.log(err)
            })
      }
   }
   setDefaultMethod(){
      const { cardID } = this.props;

      if( window.confirm('Are you sure you want set this card as default?') ){
         axios.patch(`/api/manage-card/${cardID}`)
            .then(res => {
               Store.dispatch( UPDATE_CARDS(res.data) )
            })
            .catch(err => {
               showResponse('errorResponse', err.response.data, 'Error')
            })
      }
   }

   render(){

      const { cardNumber, defaultPaymentMethod, brand } = this.props;

      //Card type
      var cardType = brand;

      if( cardType == 'Visa' ){
         cardType = Visa;
      }else if( cardType == 'MasterCard' ){
         cardType = MasterCard;
      }else if( cardType == 'Discover' ){
         cardType = Discover;
      }else if( cardType == 'Diners' || cardType == 'Diners - Carte Blanche' ){
         cardType = Diners;
      }else if( cardType == 'JCB' ){
         cardType = JCB;
      }else if( cardType == 'AMEX' ){
         cardType = AMEX;
      }else{
         cardType = defaultCard;
      }

      return(
         <div className="Card flex noBorder">
            <figure>
               <img src={cardType} alt="Mastercard"/>
               <p>
                  <span className="cardNumber">{brand} ending {cardNumber}</span>
                  {
                     defaultPaymentMethod !== undefined ?
                        <span className={classnames({
                           'default': true,
                           'defaultPaymentMethod': defaultPaymentMethod
                        })}
                        onClick={!defaultPaymentMethod ? this.setDefaultMethod : null}>{defaultPaymentMethod ? 'Default pay method' : 'Set as default'}</span>
                        :
                        null
                  }
                  
               </p>
            </figure>
            {
               !defaultPaymentMethod && defaultPaymentMethod !== undefined ?
                  <span onClick={this.deleteCard} className="delete"><MdDelete size="20" /></span>
                  :
                  null
            }
            
         </div> 
      )
   }
}


export default Card;