import React, { Component } from 'react';

import StarRatingsPlugin from 'react-star-ratings';

import './sass/StarRatings.scss';

class StarRatings extends Component{
   constructor(){
      super();
   }

   render(){

      const { rating } = this.props;
      
      return(
         <div className="StarRatings">
            <StarRatingsPlugin
               rating={parseFloat(rating)}
               starRatedColor="#4663F6"
               numberOfStars={5}
               name="rating"
               starDimension="14px"
               starSpacing="1px"
            />
            <span>{rating.toFixed(1)}</span>
         </div>
      )
   }
}

export default StarRatings;