import React, { Component } from 'react'

import Intercom from 'react-intercom'

class IntercomAbstraction extends Component {
    render() {
        const { user } = this.props

        //Chat Intercom
        let userIntercom = {}
        if (user) {
            userIntercom = {
                user_id: user.username,
                email: user.email,
                name: user.name
            }
        } else {
            userIntercom = {
                user_id: null,
                email: null,
                name: null
            }
        }

        return (
            <Intercom appID="ke7ddz8e" {...userIntercom} />
        )
    }
}

export default IntercomAbstraction;