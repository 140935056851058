
import axios from 'axios';


export default (ThisReact, limit, sun) => {

   var { 
      prime_mentor, 
      goals, 
      skills, 
      languages, 
      companies, 
      connection_preferences, 
      ratings, 
      locations, 
      keywords, 
      value_per_hour } = ThisReact.state


   axios.post('/api/get-listings-filtered', { 
      prime_mentor, 
      goals, 
      skills, 
      languages, 
      companies, 
      connection_preferences, 
      ratings, 
      locations, 
      keywords, 
      value_per_hour,

      //Show more
      limit: limit ? limit : null,
      loaded: sun ? ThisReact.state.allListingsAndMentor.length : 0
   })
      .then(res => {

         if( sun ){
            let newAllListingsAndMentor = ThisReact.state.allListingsAndMentor;
            newAllListingsAndMentor = newAllListingsAndMentor.concat( res.data );

            ThisReact.setState({allListingsAndMentor: newAllListingsAndMentor, searchMentors: false})
         }else{
            ThisReact.setState({allListingsAndMentor: res.data, searchMentors: false})

            window.scrollTo({
               top: 0,
               behavior: 'smooth'
            });
         }
      })
      .catch(err => {
         console.log(err);

         ThisReact.setState({searchMentors: false})
      })
}