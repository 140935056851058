
import getValues from './getValues'

export default thisReact => {

    //inputs Value
    const {
        listingTitle,
        location,
        listingBio,
        categories,
        skills,
        languages,
        connectionPreferences,
        linkedinProfile,
        paidListing,
        services,
        pricePerHour,
        userPhoto,
        listingBioInPlainText
    } = getValues(thisReact)

    //Validate
    let listingTitleIsValid = RegExp('[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]{10,100}$', 'g').test(listingTitle);
    let locationIsValid = thisReact.location.current._validateLocation();
    let listingBioIsValid = listingBioInPlainText.trim() != '' && listingBioInPlainText.length <= 2000 && listingBioInPlainText.length >= 350 ? true : false;
    let languageIsValid = languages.length > 0 ? true : false;
    let connectionPreferencesIsValid = connectionPreferences.length > 0 ? true : false;
    let linkedinProfileIsValid = RegExp('[a-z]{2,3}\.linkedin\.com\/.*$', 'gim').test(linkedinProfile);
    let servicesIsValid = services.find(s => s.active) && !(services.find(s => s.active && s.selected && s.selected.length == 0 || s.active && !s.selected))
    let userPhotoIsValid = userPhoto || thisReact.props.user.userPhoto.trim() != ''
    let pricePerHourIsValid = paidListing && pricePerHour != 0 && Number.isInteger(pricePerHour) || !paidListing

    
    return {
        listingTitleIsValid,
        locationIsValid,
        listingBioIsValid,
        languageIsValid,
        connectionPreferencesIsValid,
        linkedinProfileIsValid,
        servicesIsValid,
        userPhotoIsValid,
        pricePerHourIsValid
    }
}