import React, { Component } from 'react'

import './sass/Upgrade.scss'

import Footer from '../../components/Footer'
import Nav from '../../components/Menu'

//Form
import Input from '../../UI_Elements/Form/Input'
import Checkbox from '../../UI_Elements/Form/Checkbox'

import Apple from '../../assets/Upgrade/apple.svg'
import HubSpot from '../../assets/Upgrade/hubspot.svg'
import Pepsico from '../../assets/Upgrade/pepsico.svg'

import Effect1 from '../../assets/Upgrade/effect1.svg'
import Effect2 from '../../assets/Upgrade/effect2.svg'

import { connect } from 'react-redux'

import axios from 'axios'

import Response from '../../UI_Elements/Response'
import showResponse from '../../UI_Elements/Response/showResponse'

import moment from 'moment-timezone'

import Preloader from '../../UI_Elements/Preloader'

import LOG_IN_USER from '../../inc/LOG_IN_USER'

class Upgrade extends Component {

    constructor() {
        super()

        this.state = {

            //Form
            firstName: '',
            surname: '',
            email: '',
            password: '',

            couponCode: '',
            couponSend: false,

            cardInfo: null,
            terms: false,

            stripe: null,
            formSent: false,

            //error
            errorFirstname: '',
            errorSurname: '',
            errorEmail: '',
            errorPassword: '',
            errorCouponCode: '',
            errorCardInfo: '',
            errorTerms: '',
            errorCoupon: ''
        }

        this.createUserAndSubscriber = this.createUserAndSubscriber.bind(this)
        this.checkCoupon = this.checkCoupon.bind(this)
    }


    _StripeSetFields() {
        var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        var elements = stripe.elements()

        //Create fields
        var cardInfo = elements.create('card', {hidePostalCode: true})

        cardInfo.mount('#cardInfo')

        this.setState({ cardInfo, stripe })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._StripeSetFields()
    }

    checkCoupon(e) {
        e.preventDefault()

        this.setState({ couponSend: true })

        const { couponCode } = this.state

        if (couponCode.trim() == '') return this.setState({ errorCouponCode: 'Coupon not valid.', couponSend: false })

        axios.post('/api/check-coupon', { id: couponCode })
            .then(res => {
                showResponse('upgradeSuccess', 'Coupon was applied.', 'Perfect :)')
                this.setState({ couponSend: false, errorCouponCode: '' })
            })
            .catch(err => {
                showResponse('upgradeError', err.response.data, 'Ops! Coupon not valid')
                this.setState({ couponCode: '', couponSend: false })
            })

    }

    createUserAndSubscriber(e) {
        e.preventDefault();

        this.setState({ formSent: true })

        const { settings } = this.props

        //Validate
        let nameString = this.state.firstName
        let surnameString = this.state.surname
        let emailString = this.state.email
        let passwordString = this.state.password
        let terms = this.state.terms

        //info
        let couponCode = this.state.couponCode

        let nameIsValid = RegExp('[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]{1,10}$', 'g').test(nameString)
        let surnameIsValid = RegExp('[A-Z][a-zA-Z][^#&<>\"~;$^%{}?]{1,10}$', 'g').test(surnameString)
        let emailIsValid = RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}', 'g').test(emailString)
        let passwordIsValid = RegExp('(?=.{8,})', 'g').test(passwordString)
        let termsIsValid = terms


        let formIsValid = false
        if (settings.userLogged) {
            formIsValid = termsIsValid
        } else {
            formIsValid = nameIsValid && surnameIsValid && emailIsValid && passwordIsValid && termsIsValid
        }


        //name
        if (!nameIsValid && !formIsValid) {
            this.setState({ errorFirstname: 'The name must begin with a capital letter and must be at least 2 characters and a maximum of 10', formSent: false })
        } else this.setState({ errorFirstname: '' })

        //surname
        if (!surnameIsValid && !formIsValid) {
            this.setState({ errorSurname: 'The surname must begin with a capital letter and must be at least 3 characters and a maximum of 10', formSent: false })
        } else this.setState({ errorSurname: '' })

        //email
        if (!emailIsValid && !formIsValid) {
            this.setState({ errorEmail: 'Email is not valid', formSent: false })
        } else this.setState({ errorEmail: '' })

        //password
        if (!passwordIsValid && !formIsValid) {
            this.setState({ errorPassword: 'The password must be 8 characters or longer', formSent: false })
        } else this.setState({ errorPassword: '' })

        //Terms 
        if (!termsIsValid && !formIsValid) {
            this.setState({ errorTerms: 'Read the terms is required.', formSent: false })
        } else this.setState({ errorTerms: '' })


        if (formIsValid) {
            const { cardInfo, stripe } = this.state

            const thisReact = this;
            stripe.createToken(cardInfo)
                .then(function (result) {
                    if (result.error) {
                        thisReact.setState({ formSent: false })
                        return showResponse('upgradeError', result.error.message, 'Card declined')
                    }

                    axios.post('/api/upgrade', {
                        stripeToken: result.token,
                        firstName: nameString,
                        surname: surnameString,
                        email: emailString,
                        password: passwordString,
                        couponCode,
                        currentTimeZone: moment.tz.guess()
                    })
                        .then(res => {
                            if( !settings.userLogged ) LOG_IN_USER(res.data)

                            thisReact.setState({ formSent: false })

                            //Redirect to another page ?
                            if( thisReact.props.location.state && thisReact.props.location.state.back ){
                                thisReact.props.history.push(thisReact.props.location.state.path)
                            }else{
                                showResponse('upgradeDone')
                            }

                        })
                        .catch(err => {
                            console.log(err)
                            console.log(err.response)
                            showResponse('upgradeError', err.response.data, 'Ops! Something went wrong.')
                            thisReact.setState({ formSent: false })
                        })
                })
        }
    }

    render() {


        const {
            firstName,
            surname,
            email,
            password,
            couponCode,
            couponSend,
            terms,

            formSent
        } = this.state

        const {
            errorFirstname,
            errorSurname,
            errorEmail,
            errorPassword,
            errorCouponCode,
            errorCardInfo,
            errorTerms
        } = this.state

        const { settings } = this.props

        return (
            <div id="Upgrade">
                <Nav style="stylish2" />


                <Response
                    id="upgradeError"
                    type="error"
                    title=""
                    description=""
                    btnText="Try again"
                    btnAction="close"
                />
                <Response
                    id="upgradeSuccess"
                    type="success"
                    title=""
                    description=""
                    btnText="Close"
                    btnAction="close"
                />
                <Response
                    id="upgradeDone"
                    type="success"
                    title="Thanks for subscribing"
                    description="You now have access to all Mentory"
                    btnText="Find a mentor"
                    btnAction="/find"
                />

                <img id="effect1" src={Effect1} alt="Effect" />

                <section id="upgradeContainer">

                    <img id="effect2" src={Effect2} alt="Effect" />

                    <div className="content">
                        <div>
                            <div className="left">
                                <form onSubmit={this.createUserAndSubscriber} className="simple smart">
                                    <h3 className="trl upgrade title">Get instant access</h3>
                                    <div className="pricing">
                                        <p>
                                            <span className="trl upgrade form title">Pricing</span>
                                            <span className="trl upgrade form price">19,99€ per month</span>
                                            <span className="trl upgrade form cancelAnytime">Cancel anytime</span>
                                        </p>
                                    </div>

                                    <div className="containerInputs">
                                        {
                                            !settings.userLogged ?
                                                <React.Fragment>
                                                    <div>
                                                        <Input error={errorFirstname} value={firstName} onChange={value => this.setState({ firstName: value })} label="First name*" />
                                                    </div>
                                                    <div>
                                                        <Input error={errorSurname} value={surname} onChange={value => this.setState({ surname: value })} label="Surname*" />
                                                    </div>
                                                    <div>
                                                        <Input error={errorEmail} value={email} onChange={value => this.setState({ email: value })} label="Email*" type="email" />
                                                    </div>
                                                    <div>
                                                        <Input error={errorPassword} value={password} onChange={value => this.setState({ password: value })} label="Password*" type="password" />
                                                    </div>
                                                </React.Fragment>
                                                :
                                                null
                                        }

                                        <div>
                                            <div className="Input">
                                                <label className="trl upgrade form label cardInfo" htmlFor="">Coupon code</label>
                                                <div className="cuponCode">
                                                    <input value={couponCode} onChange={e => this.setState({ couponCode: e.target.value })} type="text" />
                                                    {
                                                        couponSend ?
                                                            <span className="trl upgrade form load">Load...</span>
                                                            :
                                                            <button className="trl upgrade form apply" type="button" onClick={this.checkCoupon}>apply</button>
                                                    }
                                                </div>
                                                {
                                                    errorCouponCode.trim() != '' ?
                                                        <span className="form error">{errorCouponCode}</span>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>

                                        <div>
                                            <div className="Input">
                                                <label className="trl upgrade form creditCard" htmlFor="cardInfo">Credit card*</label>
                                                <div id="cardInfo"></div>
                                            </div>
                                        </div>

                                        <div className="terms">
                                            <div>
                                                <Checkbox id="checkboxTerms" value={1} checked={terms} onChange={arrayValues => arrayValues.includes('1') ? this.setState({ terms: true }) : this.setState({ terms: false })} label="By signing up, you agree to our Privacy Policy and to receive periodic correspondence from us." />
                                                {
                                                    errorTerms.trim() != '' ?
                                                        <span className="form error">{errorTerms}</span>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        formSent ?
                                            <Preloader />
                                            :
                                            <button type="submit" className="btn trl upgrade form btn accessNow">access now</button>
                                    }

                                </form>
                            </div>

                            <div className="right">
                                <h2 className="trl upgrade right title">You just found a better way to advance your career.</h2>
                                <ul>
                                    <li className="trl upgrade right list l1">Schedule calls with a curated collection of coaches, experts and thought leaders at affordable prices, or even for free.</li>
                                    <li className="trl upgrade right list l2">Beyond being great professionals, we ensure that all our mentors are friendly and genuinely enjoy helping</li>
                                    <li className="trl upgrade right list l3">Mentor profiles are full of details and contextual information so you can find the best fit for your needs.</li>
                                    <li className="trl upgrade right list l4">Membership includes personal support and mentor’s matchmaking from the founder of Mentory to help you get the max value possible from the platform.</li>
                                    <li className="trl upgrade right list l5">Talk to your mentors using our integrated video chat platform that allows you to use share your screens, exchange files and connect in a fast and secure manner.</li>
                                </ul>
                                <div>
                                    <p className="trl upgrade right ourMentors">Our mentors have experience in companies like</p>
                                    <figure>
                                        <img src={Apple} alt="Apple" />
                                        <img src={HubSpot} alt="HubSpot" />
                                        <img src={Pepsico} alt="Pepsico" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state
}
export default connect(mapStateToProps)(Upgrade)