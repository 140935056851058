import React from 'react';
import InputRangePlugin from 'react-input-range';

import "react-input-range/lib/css/index.css";
import "./sass/inputRange.scss";

class InputRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value ? props.value : { min: 2, max: 10 },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  render() {
    return (
      <InputRangePlugin
        maxValue={300}
        minValue={0}
        value={this.state.value}
        onChange={value => {
          if (this.props.onChange) this.props.onChange(value);
          this.setState({ value })
        }}
        formatLabel={value => `USD $${value}/h`}
        allowSameValues
      />
    );
  }
}

export default InputRange;