import React, { Component } from 'react';

import LOG_IN_USER from './LOG_IN_USER';
import axios from 'axios';

import moment from 'moment-timezone';

import { withRouter } from "react-router";

import GoogleLogin from 'react-google-login'

import Response from '../UI_Elements/Response';
import showResponse from '../UI_Elements/Response/showResponse';

class GoogleOAuth extends Component {

   responseGoogle = async res => {
      //Error?
      if(res.error && res.error == 'popup_closed_by_user') return
      if (res.error) return showResponse('OAuthGoogleError', res.error)

      if (this.props.startOAuth) this.props.startOAuth()

      //Get user
      let googleUser = res.profileObj
      googleUser.timezone = moment.tz.guess()

      await axios.post('/api/auth/google', { googleUser })
         .then(res => {
            LOG_IN_USER(res.data)
            if (this.props.success) this.props.success()
         })
         .catch(err => this.props.history.push('/login'))

   }

   render() {

      const { label, BecomeaMentor } = this.props

      return (
         <div>
            <Response
               id="OAuthGoogleError"
               type="error"
               title="Ops! Something went wrong"
               description=""
               btnText="Try again"
               btnAction="close"
            />
            <div>
               {
                  BecomeaMentor ?
                     <GoogleLogin
                        clientId="946203630997-kv47jkktg9fj3gh114007q3ldhgmfigg.apps.googleusercontent.com"
                        render={renderProps => (
                           <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn large trl becomeMentor actionButton">Become a mentor</button>
                        )}s
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={'single_host_origin'}
                     />
                     :
                     <GoogleLogin
                        clientId="946203630997-kv47jkktg9fj3gh114007q3ldhgmfigg.apps.googleusercontent.com"
                        buttonText={label}
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={'single_host_origin'}
                     />
               }

            </div>
         </div>
      )
   }
}

export default withRouter(GoogleOAuth);