import React, { Component } from 'react';

import './sass/CompletedSessionMentee.scss';

import { MdMoreVert } from "react-icons/md";
import defaultPhoto from '../../../assets/default.png';

import classnames from 'classnames';

import { Link } from 'react-router-dom';

import Money from '../../../inc/Money';

import { connect } from 'react-redux';

import moment from 'moment-timezone';

class CompletedSessionMentee extends Component{
   constructor(){
      super();

      this.state = {
         openedSettingsMenu: false,
      }

      this.handleClick = this.handleClick.bind(this);

      this.containerSettings = React.createRef();
   }


   componentWillMount(){
      document.addEventListener('mousedown', this.handleClick, false)
   }
   componentWillUnmount(){
      document.removeEventListener('mousedown', this.handleClick, false)
   }
   handleClick(e){
      if( this.containerSettings.contains(e.target) ) return;

      this.setState({openedSettingsMenu: false})
   }

   render(){
      const { openedSettingsMenu } = this.state;
      const { session, settings } = this.props;

      return(
         <div className="CompletedSessionMentee trlGlobal">
            <div ref={node => this.containerSettings = node} onClick={() => this.setState({openedSettingsMenu: true})} className="settings">
               <MdMoreVert size="22" />
               <ul className={classnames({
                  'dropdownOpt': true,
                  'active': openedSettingsMenu ? true : false
               })}>
                  <li className="link"><Link className="trl CompletedSessionMentee contactMentor" to="/connect">Contact mentor</Link></li>
                  <li className="link"><Link className="trl CompletedSessionMentee viewProfile" to={`/${settings.publicProfile}/${session.mentorObject.username}`}>View profile</Link></li>
                  <li className="link"><Link className="trl CompletedSessionMentee viewListing" to={`/listing/${session.slug}`}>View listing</Link></li>
                  <li className="trl CompletedSessionMentee reportUser" onClick={this.props.onClickReportUser}>Report user</li>
                  <li className="trl CompletedSessionMentee openDispute" onClick={this.props.onClickOpenDispute}>Open a dispute</li>
               </ul>
            </div>
            <span className="photo" style={{backgroundImage: `url("${session.mentorObject.userPhoto.trim() != '' ? session.mentorObject.userPhoto : defaultPhoto}")`}}></span>
            <div>
               <h4>{session.mentorObject.name} {session.mentorObject.surname}</h4>
               <p className="description">{session.titleListing}</p>
               <p className="received"><span className="trl CompletedSessionMentee received">Received</span> {moment(session.whenRequestedSession).format('DD MMMM YYYY')}</p>
               <p className="youWillMake">{parseInt(session.price.mentee) > 0 ? <React.Fragment><span className="trl CompletedSessionMentee youPaidSpan">You paid</span> <Money value={(session.price.mentee/100)} currency={this.props.user.currency} /></React.Fragment> : <span className="trl CompletedSessionMentee free">FREE</span>}</p>
               <span className={classnames({
                  'status': true,
                  'disputed': session.status == 'disputed' ? true : false
               })}>{session.status}</span>
               {
                  !session.menteeReview ?
                     <button onClick={() => this.props.endRateSession.current.start(session)} className="btn trl CompletedSessionMentee leaveReview">leave a review</button>
                     :
                     null
               }
            </div>
         </div>
      )
   }
}


const mapStateToProps = state => {
   return state;
}
export default connect(mapStateToProps)(CompletedSessionMentee);