import React, { Component } from 'react';

import './sass/Response.scss';

import { withRouter } from "react-router";
import animateCSS from '../../inc/AnimateCSS';

//Assets
import { FiCheck, FiX, FiAlertCircle } from "react-icons/fi";


class Response extends Component {


   btnAction(){
      const { btnAction, callback, id } = this.props;

      if( btnAction.charAt(0) == '/' ){
         this.props.history.push(btnAction);
      }else if( btnAction == 'close' ){
         this.closeModal(id)
         if( callback ) callback()
      }else if(btnAction == 'reload'){
         window.location.reload();
      }else{
         throw new Error('Set CLOSE to close response or /');
      }
   }

   closeModal(){

      const { id } = this.props;

      const thisResponse = document.querySelector(`.Response#${id}`);

      animateCSS(`.Response#${id}`, 'fadeOut', function(){
         thisResponse.style.display = 'none';
      })

   }

   render(){

      const {type, title, description, btnText, id, btnToClose} = this.props;

      return (
         <div id={id} className="Response">
            <div className="containerResponse">
               <div className="box">
                  {
                     btnToClose ?
                         <span onClick={this.closeModal.bind(this)} className="close"><FiX size="25"/></span>
                         :
                         null
                  }
                 
                  <figure className={type}>
                     {
                        type == 'error' ?
                           <FiX size="75"/>
                           :
                           null
                     }
                     {
                        type == 'success' ?
                           <FiCheck size="75"/>
                           :
                           null
                     }
                     {
                        type == 'warning' ? 
                           <FiAlertCircle size="65"/>
                           :
                           null
                     }
                  </figure>

                  <h3 className={`trl response title ${window.TranslateApp.clearString(title)}`}>{title}</h3>
                  <p className={`trl response description ${window.TranslateApp.clearString(description)}`}>{description}</p>
                  <button className={`trl response btn ${window.TranslateApp.clearString(btnText)}`} onClick={this.btnAction.bind(this)}>{btnText}</button>
               </div>
            </div>
         </div>
      );
  }
}

export default withRouter(Response);