import React, { Component } from 'react';

import InputRange from 'react-rangeslider'

//CSS
import 'react-rangeslider/lib/index.css'
import './sass/BookingSessionInputTime.scss';

class BookSessionInputTime extends Component{

   constructor(props){
      super(props);

      this.format = this.format.bind(this);
      this.onChangeInputRange = this.onChangeInputRange.bind(this);

      this.state = {
         value: props.value
      }
   }

   format( value ){
      let hours = value/60;
      let time = null;

      if( hours == 0 ){
         return "12:00 AM";
      }else if( hours == .5 ){
         return "12:30 AM"
      }

      if( hours >= 12 ){
         if( Number.isInteger(hours) ){
            time = (hours-12 == 0 ? '12' : hours-12 ) + ':00 PM'
         }else time = parseInt( (hours-12 == .5 ? '12' : hours-12) ) + ':30 PM'
      }else{
         if( Number.isInteger(hours) ){
            time = hours + ':00 AM'
         }else time = parseInt(hours) + ':30 AM'
      }

      if( this.props.getDate ){
         this.props.getDate(time);
      }

      return time;
   }

   onChangeInputRange(value){

      if( this.props.onChange ) this.props.onChange(value);
      
      if( this.props.formated ) this.props.formated( this.format(value) );
   }

   componentWillReceiveProps(props){
      this.setState({value: props.value})
      
   }

   render(){

      const { value } = this.state;
      const { tooltip } = this.props;


      return (
         <InputRange
            value={value}
            min={0}
            max={(48 * 30)-30}
            step={30}
            tooltip={tooltip === undefined || tooltip === true  ? true : false}
            orientation="horizontal"
            onChange={value => this.onChangeInputRange(value)}
            onChangeComplete={() => this.onChangeInputRange(value)}
            labels={{0: <span>12:00<br/>AM</span>, 348: <span>06:00<br/>AM</span>, 720: <span>12:00<br/>PM</span>, 1122: <span>06:00<br/>PM</span>, 1440: <span>11:30<br/>PM</span>}}
            format={value => this.format(value)}
         />
      )
   }
}

export default BookSessionInputTime;