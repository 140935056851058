import React, { Component } from 'react';

//SASS
import './sass/FindMentor.scss';


//Components
import Nav from '../../components/Menu';
import CardFindMentor from '../../components/CardFindMentor';

import Checkbox from '../../UI_Elements/Form/Checkbox';
import Input from '../../UI_Elements/Form/Input';
import InputRange from '../../UI_Elements/Form/InputRange';
import Collapsible from '../../UI_Elements/Collapsible';
import Popup from '../../UI_Elements/Popup';
import Tooltip from '../../UI_Elements/Tooltip';
import Location from '../../UI_Elements/Form/Location';

//Assets
import { MdKeyboardArrowDown, MdInfoOutline, MdStar, MdLocationOn, MdSearch } from "react-icons/md";
import { GoSettings } from "react-icons/go";
import { FiSearch } from "react-icons/fi";

//Redux
import { connect } from 'react-redux';


import Preloader from '../../UI_Elements/Preloader';

import FilterFunction from './filter';

class FindMentor extends Component {
   constructor() {
      super();

      this.state = {
         openFilterBox: false,
         allListingsAndMentor: [],

         //Search mentors
         searchMentors: true,
         companiesFiltered: [],

         //Filters
         prime_mentor: false,
         goals: [],
         skills: [],
         languages: [],
         companies: [],
         connection_preferences: [],
         ratings: [],
         locations: '',
         keywords: '',
         value_per_hour: { min: 0, max: 300 },
      }

      this.apllyFilters = this.apllyFilters.bind(this)
      this.clearAll = this.clearAll.bind(this)
      this.onChangeFilter = this.onChangeFilter.bind(this)

      this.keywords = React.createRef()
      this.collapsiblePrimeMentor = React.createRef()
      this.collapsibleGoals = React.createRef()
      this.collapsibleSkills = React.createRef()
      this.collapsibleLanguages = React.createRef()
      this.collapsibleCompanies = React.createRef()
      this.collapsibleConnectionPreferences = React.createRef()
      this.collapsibleRatings = React.createRef()
   }

   async clearAll(e) {
      e.preventDefault()

      await this.setState({
         prime_mentor: false,
         goals: [],
         skills: [],
         languages: [],
         companies: [],
         connection_preferences: [],
         ratings: [],
         locations: '',
         keywords: '',
         value_per_hour: { min: 0, max: 300 }
      })

      FilterFunction(this)
   }




   apllyFilters(e) {
      e.preventDefault();

      this.setState({ searchMentors: true })

      FilterFunction(this);

      this.setState({ openFilterBox: false })
   }

   //Filter change?
   onChangeFilter(val, type) {
      this.setState({ [type]: val })
   }



   componentDidMount() {
      window.scrollTo(0, 0)

      FilterFunction(this);
   }

   componentWillMount() {

      //Get filters from another pages
      const locationState = this.props.location.state

      if (locationState) {
         if (locationState.goal) {
            let { goals } = this.state

            goals.push(locationState.goal)

            return this.setState({ goals })
         }
         if (locationState.keywords) {
            return this.setState({ keywords: locationState.keywords })
         }
      }

   }

   render() {

      const { openFilterBox, allListingsAndMentor, searchMentors, companiesFiltered } = this.state;
      const { userLogged } = this.props.settings
      const { user } = this.props

      return (
         <div id="FindMentor">
            <Nav style="stylish" />

            <section className="findMentorContainer">
               <div className="content">

                  <h2 className="trl findMentor title">Find a mentor</h2>

                  <div className="search flex">

                     <button onClick={() => {
                        document.querySelector('body').className = 'noScroll';

                        this.setState({ openFilterBox: true })
                     }} className="showFilter btn"><GoSettings size={27} /> <span className="trl findMentor filterMobile">filter</span></button>

                     <Popup open={openFilterBox} popupActiveWhen={768}>
                        <div className="Filter">
                           <p className="flex">
                              <span className="trl findMentor narrowBy">Narrow by</span>
                              <span className="trl findMentor clearAll" onClick={e => this.clearAll(e)}>clear all</span>
                           </p>

                           <form id="FilterForm" className="FilterForm simple" onSubmit={this.apllyFilters}>

                              {/*
                              <div className="FilterBox">
                                 <strong>
                                    <span onClick={e => this.collapsiblePrimeMentor.current.toggleCollapsible(e)} className="trl findMentor primeMentor">Prime mentor</span>
                                    <Tooltip name="primeMentorTooltip" placement="left" tooltip={<React.Fragment>Prime mentors are known for being thought<br /> leaders in their fields or successful entrepreneurs.</React.Fragment>}>
                                       <MdInfoOutline />
                                    </Tooltip>
                                 </strong>

                                 <Collapsible ref={this.collapsiblePrimeMentor} btnClose={<MdKeyboardArrowDown size="25" />}>
                                    <Checkbox checked={this.state.prime_mentor ? true : false} id="CheckboxPrimeMentorFilter" onChange={primeMentor => this.onChangeFilter(primeMentor, 'prime_mentor')} label="Prime mentor" />
                                 </Collapsible>
                              </div>*/}

                              <div className="FilterBox">
                                 <strong onClick={e => this.collapsibleGoals.current.toggleCollapsible(e)} className="trl findMentor goals">Goals</strong>

                                 <Collapsible ref={this.collapsibleGoals} btnClose={<MdKeyboardArrowDown size="25" />}>
                                    {
                                       this.props.settings.services.map((service, i) => {
                                          return <Checkbox checked={this.state.goals.find(s => s == service.title) ? true : false} key={i} id="CheckboxCategoriesFilter" onChange={service => this.onChangeFilter(service, 'goals')} label={service.title} />
                                       })
                                    }
                                 </Collapsible>
                              </div>


                              <div className="FilterBox">
                                 <strong onClick={e => this.collapsibleLanguages.current.toggleCollapsible(e)} className="trl findMentor language">Language</strong>

                                 <Collapsible ref={this.collapsibleLanguages} open={false} btnClose={<MdKeyboardArrowDown size="25" />}>
                                    {
                                       ["Spanish", "English"].map((label, i) => {
                                          return <Checkbox checked={this.state.languages.includes(label) ? true : false} key={i} id="CheckboxLanguagesFilter" onChange={languages => this.onChangeFilter(languages, 'languages')} label={label} />
                                       })
                                    }
                                 </Collapsible>

                              </div>

                              {/*
                              <div className="FilterBox">
                                 <strong onClick={e => this.collapsibleCompanies.current.toggleCollapsible(e)} className="trl findMentor company">Company</strong>

                                 <Collapsible ref={this.collapsibleCompanies} onClick={e => this.collapsibleCompanies.current.toggleCollapsible(e)} open={false} btnClose={<MdKeyboardArrowDown size="25" />}>
                                    <Input
                                       className="filterInput"
                                       placeholder="Search"
                                       icon={<FiSearch />}
                                       onChange={value => {
                                          var companiesFiltered = this.props.settings.companies.filter(s => s.toLowerCase().includes((value.toLowerCase())));
                                          this.setState({ companiesFiltered })
                                       }}
                                    />

                                    {
                                       this.props.settings.companies.map((company, i) => {
                                          return <Checkbox checked={this.state.companies.includes(company) ? true : false} key={i} id="CheckboxCompanyFilter" onChange={companies => this.onChangeFilter(companies, 'companies')} className={companiesFiltered.length != 0 ? companiesFiltered.includes(company) ? false : 'hide' : null} label={company} />
                                       })
                                    }
                                 </Collapsible>
                              </div>*/}

                              <div className="FilterBox">
                                 <strong onClick={e => this.collapsibleConnectionPreferences.current.toggleCollapsible(e)} className="trl findMentor connectionPreferences">Connection preferences</strong>

                                 <Collapsible ref={this.collapsibleConnectionPreferences} open={false} btnClose={<MdKeyboardArrowDown size="25" />}>
                                    {
                                       this.props.settings.connectionPreferences.map((connectionPreference, i) => {
                                          return <Checkbox checked={this.state.connection_preferences.includes(connectionPreference) ? true : false} key={i} id="CheckboxConnectionPreferenceFilter" onChange={connectionPreferences => this.onChangeFilter(connectionPreferences, 'connection_preferences')} label={connectionPreference} />
                                       })
                                    }
                                 </Collapsible>

                              </div>

                              <Location value={this.state.locations} id="LocationFilter" onChange={address => this.setState({ locations: address })} placeholder="Location" icon={<MdLocationOn size="18" />} />
                              <Input value={this.state.keywords} ref={this.keywords} id="KeywordsFilter" onChange={keywords => this.onChangeFilter(keywords, 'keywords')} placeholder="Keywords" icon={<MdSearch size="20" />} />

                              <InputRange value={this.state.value_per_hour} onChange={valuePerHour => this.onChangeFilter(valuePerHour, 'value_per_hour')} />

                              <div className="actions">
                                 <span onClick={() => {
                                    document.querySelector('body').className = '';
                                    this.setState({ openFilterBox: false })
                                 }}>Cancel</span>
                                 {
                                    searchMentors ?
                                       <Preloader />
                                       :
                                       <button type="submit" className="btn trl findMentor applyFilters">Apply filters</button>
                                 }
                              </div>
                           </form>
                        </div>
                     </Popup>


                     <div className="MentorList">
                        {
                           searchMentors ?
                              <Preloader />
                              :
                              <React.Fragment>
                                 <h4>{allListingsAndMentor.length} <span className="trl findMentor mentorsFound">mentors found</span></h4>
                                 <div>
                                    {
                                       allListingsAndMentor.length > 0 ?
                                          allListingsAndMentor.map((listingAndMentor, i) => {
                                             return <CardFindMentor
                                                key={i}
                                                listing={listingAndMentor.listing}
                                                mentor={listingAndMentor.mentor}
                                                blocked={i > 3 && !userLogged}
                                             />
                                          })
                                          :
                                          <h4 className="trl findMentor notFound">Try changing your search filters 🔎.</h4>
                                    }
                                 </div>
                              </React.Fragment>
                        }
                        {
                           allListingsAndMentor.length > 12 ?
                              <button onClick={() => FilterFunction(this, 4, true)} className="btn large">show more</button>
                              :
                              null
                        }

                     </div>

                  </div>

               </div>
            </section>

         </div>
      )
   }
}

const mapStateToProps = state => {
   return state;
}
export default connect(mapStateToProps)(FindMentor);