import React, { Component } from 'react';

import Input from '../../UI_Elements/Form/Input';

import './sass/ResetPassword.scss';

import { withRouter } from "react-router";
import { Link } from "react-router-dom";

//Assets
import { MdClose } from "react-icons/md";

import axios from 'axios';

import Response from '../../UI_Elements/Response';
import showResponse from '../../UI_Elements/Response/showResponse';

import Preloader from '../../UI_Elements/Preloader';


class ResetPassword extends Component{

   constructor(props){
      super(props);


      this.state = {
         formSent: false,
      }

      this.UPDATE_PASSWORD_NO_LOGGED = this.UPDATE_PASSWORD_NO_LOGGED.bind(this);

      this.newPassword = React.createRef();
      this.confirmNewPassword = React.createRef();
   }

   UPDATE_PASSWORD_NO_LOGGED(e){
      e.preventDefault();

      this.setState({formSent: true});

      const newPassword = this.newPassword.current.state.inputValue;
      const confirmNewPassword = this.confirmNewPassword.current.state.inputValue;

      axios.post(`/api/check-token-reset-password/${this.props.match.params.token}`, {newPassword, confirmNewPassword})
         .then(res => {
            console.log(res)
            
            showResponse('resetPasswordSuccess')

            this.setState({formSent: false});
         })
         .catch(err => {
            console.log(err);
            showResponse('resetPasswordError', err.response.data.description, err.response.data.title)
            
            this.setState({formSent: false});
         })

   }

   render(){

      const { formSent } = this.state;

      return(
         <div id="ResetPassword">
            <Response 
               id="resetPasswordError"
               type="error"
               title=""
               description=""
               btnText="Close"
               btnAction="close"
            />
            <Response 
               id="resetPasswordSuccess"
               type="success"
               title="Password reseted"
               description="Now you can log with your new password"
               btnText="Go to login page"
               btnAction="/login"
            />

            <section>
               <div className="content">

                  <Link className="back" to="/"><MdClose size="35" /></Link>

                  <div>
                     <h2>Reset your password</h2>
                     <p className="subtitle">Enter a new password.</p>

                     <form onSubmit={this.UPDATE_PASSWORD_NO_LOGGED} className="simple">
                        <Input ref={this.newPassword} placeholder="New password" />
                        <Input ref={this.confirmNewPassword} placeholder="Confirm new password" />
                        {
                           formSent ?
                              <Preloader />
                              :
                              <button type="submit" className="btn large">update password</button>
                        }
                     </form>
                  </div>

               </div>
            </section>
         </div>
      )
   }
}

export default withRouter(ResetPassword);