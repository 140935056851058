import React, { Component } from 'react';


//SASS
import './sass/CardFindMentor.scss';

//Assets
import defaultPhoto from '../../assets/default.png';
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FiGlobe, FiMapPin, FiSmartphone } from "react-icons/fi";
import StarRatings from '../../UI_Elements/StarRatings';

import { Link } from "react-router-dom";
import classnames from 'classnames';

//Redux
import { connect } from 'react-redux';

import ADD_FAVOURITE from '../../inc/ADD_FAVOURITE';
import REMOVE_FAVOURITE from '../../inc/REMOVE_FAVOURITE';

import ShowPrice from '../../inc/ShowPrice'


class CardFindMentor extends Component {
   constructor() {
      super()

      this.state = {
         favourites: false,
      }

      this.add_favourite = this.add_favourite.bind(this);
      this.remove_favourite = this.remove_favourite.bind(this);
   }

   add_favourite(e) {
      e.preventDefault();
      e.stopPropagation();

      const { listing } = this.props

      ADD_FAVOURITE(listing.slug)
   }
   remove_favourite(e) {
      e.preventDefault();
      e.stopPropagation();

      const { listing } = this.props

      REMOVE_FAVOURITE(listing.slug);
   }

   render() {
      const { listing, mentor, user, blocked } = this.props

      //Summarize plain text
      let descArray = listing.descriptionPlainText.split(' ')

      return (
         <div className={classnames({
            'CardFindMentor': true,
            'blocked': blocked,
            'trlGlobal': true,
            'on': this.props.settings.allUsersStatus[mentor.username] ? this.props.settings.allUsersStatus[mentor.username].online : false
         })}
            onClick={() => {
               if (!blocked) {
                  if (window.innerWidth <= 700) {
                     window.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/listing/${listing.slug}`
                  } else {
                     window.open(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/listing/${listing.slug}`)
                  }
               }
            }}>

            {
               blocked ?
                  <Link to="/signup">
                     <button className="btn large trl cardFindMentor btn registerToViewProfile">REGISTER TO VIEW PROFILE</button>
                  </Link>
                  :
                  null
            }

            <div className="container">

               <div className="flex">
                  <div className="first">
                     <span style={{ backgroundImage: `url("${mentor.userPhoto.trim() != '' ? mentor.userPhoto : defaultPhoto}")` }} className="photo"></span>
                     <ShowPrice pricePerHour={listing.pricePerHour} currency={mentor.currency} />
                  </div>

                  <div className="middle">
                     <h3 className="name">
                        {mentor.name} {mentor.surname}
                     </h3>
                     <p className="title">
                        {
                           !blocked ?
                              listing.title
                              :
                              'Lorem ipsum is a name for a common type of placeholder text.'
                        }
                     </p>

                     {
                        listing.rating > 0 ?
                           <StarRatings rating={(listing.rating / listing.reviews.length)} />
                           :
                           <small className="trl cardFindMentor noReviewsYet"><i>No reviews yet</i></small>
                     }

                     <ul>
                        <li className="flex">
                           <span><FiMapPin size="15" /> {listing.location}</span>
                           <span><FiGlobe size="15" /> {listing.languages.join(', ')}</span>
                        </li>
                        <li><FiSmartphone size="17" /> {listing.connectionPreferences.join(', ')}</li>
                        {/* <li><GoBookmark size="17" /> Prime mentor</li> */}
                     </ul>
                  </div>

                  <div className="third flex">
                     <button
                        onClick={e => {
                           e.preventDefault();
                           e.stopPropagation();

                           if (!blocked) {
                              if (window.innerWidth <= 700) {
                                 window.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/listing/${listing.slug}`
                              } else {
                                 window.open(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/listing/${listing.slug}`)
                              }
                           }

                        }}
                        className="trl cardFindMentor viewListing btn white">
                        view listing
                  </button>

                     <p className="favourite">
                        {
                           user ?
                              user.myFavourites.length > 0 ?
                                 !user.myFavourites.find(favourite => favourite.slug == listing.slug) ?
                                    <React.Fragment>
                                       <FaRegHeart size="15" />
                                       <span className="trl cardFindMentor addToFavourites" onClick={this.add_favourite}>add to favourites</span>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                       <FaHeart className="animated bounceIn" size="15" />
                                       <span className="trl cardFindMentor removeFavourite" onClick={this.remove_favourite}>Remove favourite</span>
                                    </React.Fragment>

                                 :

                                 <React.Fragment>
                                    <FaRegHeart size="15" />
                                    <span className="trl cardFindMentor addToFavourites" onClick={this.add_favourite}>add to favourites</span>
                                 </React.Fragment>
                              :
                              null

                        }
                     </p>
                  </div>
               </div>

               <div className="infoCardFindMentor">
                  <p className="desc">
                     {
                        blocked ?
                           'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque, fuga vitae pariatur aperiam, tenetur eligendi mollitia ex sit, animi blanditiis distinctio labore placeat ullam in! Praesentium, suscipit. Harum, iste reprehenderit!'
                           :
                           descArray.map((word, i) => {
                              if (i <= 40) return word + ' '
                              if (i == 41) return '[...]'
                           })
                     }

                  </p>

                  <div className="flex">
                     {
                        listing.services.map((s, i) => {
                           if (!s.active) return
                           return <a key={i} className="tag" href="">{s.title}</a>
                        })
                     }
                  </div>
               </div>

            </div>
         </div>
      )
   }
}



const mapStateToProp = state => {
   return state;
}
export default connect(mapStateToProp)(CardFindMentor);