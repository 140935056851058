import React, { Component } from 'react';

import './sass/CompletedSessionMentor.scss';

import { MdMoreVert } from "react-icons/md";
import defaultPhoto from '../../../assets/default.png';

import classnames from 'classnames';

import { Link } from 'react-router-dom';

import Money from '../../../inc/Money';

import { connect } from 'react-redux';

import moment from 'moment-timezone';


class CompletedSessionMentor extends Component{
   constructor(){
      super();

      this.state = {
         openedSettingsMenu: false,
      }

      this.handleClick = this.handleClick.bind(this);

      this.containerSettings = React.createRef();
   }


   componentWillMount(){
      document.addEventListener('mousedown', this.handleClick, false)
   }
   componentWillUnmount(){
      document.removeEventListener('mousedown', this.handleClick, false)
   }
   handleClick(e){
      if( this.containerSettings.contains(e.target) ) return;

      this.setState({openedSettingsMenu: false})
   }

   render(){
      const { openedSettingsMenu } = this.state;
      const { session, settings } = this.props;

      return(
         <div className="CompletedSessionMentor trlGlobal">
            <div ref={node => this.containerSettings = node} onClick={() => this.setState({openedSettingsMenu: true})} className="settings">
               <MdMoreVert size="22" />
               <ul className={classnames({
                  'dropdownOpt': true,
                  'active': openedSettingsMenu ? true : false
               })}>
                  <li className="link"><Link className="trl CompletedSessionMentor viewProfile" to={`/${settings.publicProfile}/${session.menteeObject.username}`}>View Profile</Link></li>
                  <li className="link"><Link className="trl CompletedSessionMentor contactMentee" to="/connect">Contact Mentee</Link></li>
                  <li className="trl CompletedSessionMentor reportUser" onClick={this.props.onClickReportUser}>Report User</li>
                  <li className="trl CompletedSessionMentor openDispute" onClick={this.props.onClickOpenDispute}>Open a dispute</li>
               </ul>
            </div>
            <span className="photo" style={{backgroundImage: `url("${session.menteeObject.userPhoto.trim() != '' ? session.menteeObject.userPhoto : defaultPhoto}")`}}></span>
            <div>
               <h4>{session.menteeObject.name} {session.menteeObject.surname}</h4>
               <p className="description">{session.titleListing}</p>
               <p className="received"><span className="trl CompletedSessionMentor received">Received</span> {moment(session.whenRequestedSession).format('DD MMMM YYYY')}</p>
               <p className="youWillMake">{parseInt(session.price.mentor) > 0 ? <React.Fragment><span className="trl CompletedSessionMentor youMadeSpan">You made</span> <Money value={(session.price.mentor/100)} currency={this.props.user.currency} /></React.Fragment> : <span className="trl CompletedSessionMentor free">FREE</span>}</p>
               <span className={classnames({
                  'status': true,
                  'disputed': session.status == 'disputed' ? true : false
               })}>{session.status}</span>
            </div>
         </div>
      )
   }
}


const mapStateToProps = state => {
   return state;
}
export default connect(mapStateToProps)(CompletedSessionMentor);