//Draftjs
import { convertToRaw } from 'draft-js';

export default thisReact => {
    return { 
        listingTitle: thisReact.listingTitle.current.state.inputValue,
        location: thisReact.location.current.state.addressFromGoogleMapsPlace,
        listingBio: convertToRaw(thisReact.listingBio.current.state.editorState.getCurrentContent()),
        languages: thisReact.languages.current.state.tagsListSelected,
        connectionPreferences: thisReact.connectionPreferences.current.state.tagsListSelected,
        linkedinProfile: thisReact.linkedinProfile.current.state.inputValue,
        paidListing: thisReact.state.listingPaid,
        services: thisReact.state.services,
        pricePerHour: thisReact.state.pricePerHour,
        userPhoto: thisReact.state.userPhoto,
        listingBioInPlainText: thisReact.listingBio.current.state.editorState.getCurrentContent().getPlainText()
    }
}