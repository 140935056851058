import React, { Component } from 'react';

import './sass/TheIdea.scss';

import Footer from '../../components/Footer';
import SignUp from '../../components/SignUp';
import Nav from '../../components/Menu';

import Juan from './media/juan.png';
import Detail1 from './media/detail_1.svg';
import Detail2 from './media/detail_2.svg';

import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaRegEnvelope } from "react-icons/fa";


class TheIdea extends Component{

   componentDidMount(){
      window.scrollTo(0, 0);
   }

   render(){
      return(
         <div id="TheIdea">

            <Nav style="stylish" />

            <img id="detail1" src={Detail1} alt="Details"/>

            <section>
               <div className="content">
                  <h1 className="trl theIdea theIdeaTitle">The idea</h1>
                  
                  <article>
                     <div>
                        <span style={{backgroundImage: `url(${Juan})`}} className="photo"></span>
                        <p>Juan M. Ramos</p>
                        <ul>
                           <li className="social">
                              <a target="_blank" href="https://www.linkedin.com/in/juanmramos/"><FaLinkedinIn size="15" /></a>
                              <a href="mailto:mentory.me@gmail.com"><FaRegEnvelope size="15" /></a>
                           </li>
                        </ul>
                     </div>

                     <div>
                        <p>
                           <span className="trl theIdea text line1">Hey,</span>
                           <br/><br/>
                           <span className="trl theIdea text line2">This is Juan M. Ramos, an entrepreneur, maker and personal development passionate who’s here to help you become best version of yourself.</span>
                           <br/><br/>
                           <span className="trl theIdea text line3">I started Mentory when I wanted to find a mentor to improve my people management skills but couldn’t find any platform that would allow me to find and connect with one on a recurrent basis, unless I was ok with paying crazy amounts of money that I did not have.</span>
                           <br/><br/>  
                           <span className="trl theIdea text line4">This led me to think that if collaborative economy has changed the way that we travel, order food and consume media, why wouldn’t I be able to do same with personal development?</span>
                           <br/><br/>
                           <span className="trl theIdea text line5">My vision is to create a global platform that allows like minded people to connect and exchange experiences that ultimately will make them better at what they do. I want to contribute to shape tomorrow’s leaders.</span>
                           <br/><br/>
                           <span className="trl theIdea text line6">Whether you’re starting or scaling your business, or looking to drive change in your personal life, there’s a fellow Mentory here ready to help you reach your full potential and coach you to design the life that you want.</span>
                           <br/><br/>
                           <span className="trl theIdea text line7">Can’t wait to see what you achieve.</span>
                        </p>
                     </div>
                  </article>
               </div>

               <img id="detail2" src={Detail2} alt="Details"/>
            </section>

            <SignUp />
            <Footer />
         </div>
      )
   }
}

export default TheIdea;