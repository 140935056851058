import React, { Component } from 'react';

import PropTypes from 'prop-types';

class InputTag extends Component{
   constructor(props){
      super(props);

      //Validate info

      this.state = {
         inputValue: '',
         tagsListSelected: props.tagsSelected ? props.tagsSelected : [],
         tags: props.tags ? props.tags : [],
         tagsSuggestions: [],
         cursor: -1,

         inputTagTop: "82px",
      }

      this.inputTagChange = this.inputTagChange.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.selectTag = this.selectTag.bind(this);

      this.inputTag = React.createRef();
      this.node = React.createRef();
      this.ulListAutocomplete = React.createRef();
   }


   inputTagChange(e){
      const { tags, tagsListSelected } = this.state;
      const inputValue = e.target.value;

      if( inputValue.trim() == '' ){
         this.setState({inputValue, tagsSuggestions: tags.diff(tagsListSelected)});
         return;
      }

      const tagsNotSelected = tags.diff(tagsListSelected);

      const tagsSuggestions = tagsNotSelected.filter(tag => {
         let reg = new RegExp("^" + inputValue, "i");
         return reg.test(tag)
      })

      this.setState({inputValue, tagsSuggestions})

   }

   selectTag(tag, e){

      e.preventDefault();
      e.stopPropagation();

      const { tagsListSelected, tags } = this.state;

      let newTagsSelected = [...tagsListSelected];
      newTagsSelected.push(tag);

      this.setState({
         tagsListSelected: newTagsSelected, 
         inputValue: '',
         tagsSuggestions: [],
         cursor: -1
      })

      this.inputTag.current.focus();
   }
   handleKeyDown(e) {

      const { cursor, tagsSuggestions, tagsListSelected, inputValue, tags } = this.state

      //Delete
      if( e.keyCode == 8 && inputValue.trim() === '' ){
         let newTagsListSelected = [...tagsListSelected];
         newTagsListSelected.pop();

         this.setState({tagsListSelected: newTagsListSelected, cursor: 0, tagsSuggestions: tags.diff(newTagsListSelected)})
      }
      /******/


      //Enter
      if( e.keyCode == 13 ){
         e.preventDefault();

         let newTagsSelected = [...tagsListSelected];
         newTagsSelected.push(tagsSuggestions[cursor]);

         this.setState({
            tagsListSelected: newTagsSelected, 
            inputValue: '',
            tagsSuggestions: [],
            cursor: -1
         })
      }
      /******/

      //Arrows
      if (e.keyCode === 38 && cursor > 0) {
        this.setState( prevState => ({
          cursor: prevState.cursor - 1
        }))
      } else if (e.keyCode === 40 && cursor < tagsSuggestions.length - 1) {
        this.setState( prevState => ({
          cursor: prevState.cursor + 1
        }))
      }
   }

   componentWillMount(){
      document.addEventListener('mousedown', this.handleClick, false)
   }
   componentWillUnmount(){
      document.removeEventListener('mousedown', this.handleClick, false)
   }
   handleClick(e){
      if( this.node.contains(e.target) ) return;

      this.setState({tagsSuggestions: []})
   }


   render(){

      const { label, icon, placeholder, error } = this.props;
      const { inputValue, tagsListSelected, tags, cursor, tagsSuggestions, inputTagTop } = this.state;

      return(
         <div ref={node => this.node = node} onClick={() => {
            this.setState({tagsSuggestions: tags.diff(tagsListSelected)})
            this.inputTag.current.focus();
         }} className="InputTag">
            {
               label ?
                  <label className={`${label ? 'trl label ' + window.TranslateApp.clearString(label) : ''}`}>{label}</label>
                  :
                  null
            }
            
            <p className="flex">

               <span className="flex">
                  {
                     tagsListSelected.map((tag, i ) => {
                        return <span key={i}>{tag}</span>
                     })
                  }
                  
                  <input 
                     ref={this.inputTag} onKeyDown={ e => this.handleKeyDown(e) }
                     style={ tagsListSelected.length == 0 ? {paddingLeft: "15px"} : null}
                     value={inputValue}   
                     placeholder={placeholder} 

                     onFocus={() => {
                        //this.setState({
                        //   tagsSuggestions: tags.diff(tagsListSelected)
                        //})
                     }} 

                     onChange={e => this.inputTagChange(e)} type="text" />
               </span>
               {
                  icon ?
                     icon
                     :
                     null
               }
            </p>
            {
               error ?
                  <span className={`trl form error ${window.TranslateApp.clearString(error)}`}>{error}</span>
                  :
                  null
            }


            <ul ref={this.ulListAutocomplete} style={{top: inputTagTop, border: tagsSuggestions.length == 0 ? 'none' : null}} className="autocomplete">
               {
                  tagsSuggestions.map((tag, i) => {
                     return <li p={i} onClick={e => this.selectTag(tag, e)} key={i} className={cursor === i ? 'active' : null}>{tag}</li>
                  })
               }
               {
                  tagsSuggestions.length == 0 && inputValue.trim() != '' ?
                     <li className="notFound">{tags.length !== tagsListSelected.length ? 'Not found' : 'All selected'}</li>
                     :
                     null
               }
            </ul>

            

         </div>
      )
   }
}

InputTag.propTypes = {
   tagsSelected: PropTypes.array,
   tags: PropTypes.array
}

export default InputTag;