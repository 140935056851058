import React, { Component } from 'react';

import { MdKeyboardArrowRight } from "react-icons/md";
import ReactSlick from "react-slick";

const reactSlickSettings = {
    dots: true,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <button><MdKeyboardArrowRight size="35" /></button>,
};

class TestimonialsCard extends Component {
    render() {

        const { children } = this.props

        return (
            <React.Fragment>
                <ReactSlick {...reactSlickSettings}>
                    {children}
                </ReactSlick>
                <div className="desktop">
                    {children}
                </div>
            </React.Fragment>
        )
    }
}

export default TestimonialsCard