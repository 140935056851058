import React, { Component } from 'react';

import classnames from 'classnames';

class Input extends Component{
   constructor(props){
      super(props);

      this.state = {
         inputValue: props.value ? props.value : '', 
      }

      this.inputChange = this.inputChange.bind(this);
      this.onKeyPress   = this.onKeyPress.bind(this);
      this._setValue   = this._setValue.bind(this);
   }


   _setValue(value){
      this.setState({inputValue: value})
   }
   inputChange(e){
      this.setState({inputValue: e.target.value});

      if( this.props.onChange ){
         this.props.onChange(e.target.value);
      }
   }

   onKeyPress(e){
      const { validatedueDate, validateOnlyNumbers } = this.props;
      var value = e.target.value;


      //validatedueDate
      if( validatedueDate ){
         if( e.keyCode != 8 ){
            //Only numbers
            if( e.charCode >= 48 && e.charCode <= 57 ){

               if( value.length >= 5 ){
                  value = value.slice(0, -1);
               }else if( value.length == 2 ){
                  value += '/';
               }else if( value.length == 3 && !value.includes('/') ){
                  value = value.slice(0, value.length-1) + '/';
               }

               this.setState({inputValue: value})
            }else{
               e.preventDefault();
               e.stopPropagation();
            }
         }
      }


      //validateOnlyNumbers
      if( validateOnlyNumbers ){
         //OnlyNumbers
         if( e.charCode >= 48 && e.charCode <= 57 ){
            this.setState({inputValue: value})
         }else{
            e.preventDefault();
            e.stopPropagation();
         }
      }

   }

   UNSAFE_componentWillReceiveProps(nextProps){
      if(nextProps.value!==this.props.value){
         this.setState({ inputValue: nextProps.value })
      }
   }


   render(){

      const { label, icon, placeholder, maxLength, textarea, className, name, error, description, type, id } = this.props;
      const { inputValue } = this.state;

      return(
         <div id={id ? id : null} className={classnames({
            'Input': true,
            [className]: className ? true : false
         })}>
            {
               label ?
                  <label className={`${label && window.TranslateApp.clearString(label) ? 'trl label ' + window.TranslateApp.clearString(label) : ''}`}>{label}</label>
                  :
                  null
            }
            
            <p className={classnames({
               'flex': true,
               'textarea': textarea ? true : false,
            })}>
               {
                  textarea ?
                     <textarea className={`trl input ${id}`} onChange={e => this.inputChange(e)} placeholder={placeholder} value={inputValue}></textarea>
                     :
                     <input className={`trl input ${id}`} onKeyPress={this.onKeyPress} name={name} maxLength={maxLength} value={inputValue} placeholder={placeholder} onChange={e => this.inputChange(e)} type={type ? type : 'text'} />
               }
               {
                  icon ?
                     icon
                     :
                     null
               }
            </p>
            {
               description ?
                  <small className={`trl description ${window.TranslateApp.clearString(description)}`}>{description}</small>
                  :
                  null
            }
            {
               error ?
                  <span className={`form error trl error ${window.TranslateApp.clearString(error)}`}>{error}</span>
                  :
                  null
            }
         </div>
      )
   }
}

export default Input;