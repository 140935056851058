import React, { Component } from 'react';

import classnames from 'classnames';

import './sass/Menu.scss';

//Assets
import { MdMenu } from "react-icons/md";
import Logo from '../../assets/logo.png';

import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { MdClose } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

import { MdSwapCalls } from "react-icons/md";
import { FiPlus, FiList, FiBookOpen, FiUser, FiSettings, FiLogOut, FiUsers } from "react-icons/fi";

import defaultUser from '../../assets/default.png';

//Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import Store from '../../redux/store';

//Axios config
import axios from 'axios';

import { SETTINGS } from '../../redux/actions/index';



class Menu extends Component {
   constructor() {
      super();

      this.state = {
         menuMobileStatus: false,
         menuDesktopLoggedStatus: false,

         LOGGED: Store.getState().settings.userLogged,
      }

      this._btnMenuMobileOpen = this._btnMenuMobileOpen.bind(this);
      this._btnMenuDesktopLoggedToggle = this._btnMenuDesktopLoggedToggle.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.logOut = this.logOut.bind(this);

      this.btnToggleMenuDesktopLogged = React.createRef();
   }

   _btnMenuMobileOpen() {
      this.setState({ menuMobileStatus: true });
   }
   _btnMenuDesktopLoggedToggle() {
      this.setState({ menuDesktopLoggedStatus: !this.state.menuDesktopLoggedStatus });
   }
   _btnMenuMobileLoggedToggle() {
      this.setState({ menuMobileStatus: !this.state.menuMobileStatus });
   }


   componentWillMount() {
      document.addEventListener('mousedown', this.handleClick, false)
   }
   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClick, false)
   }
   handleClick(e) {
      const { LOGGED } = this.state;

      if (!LOGGED) return;

      if (this.btnToggleMenuDesktopLogged.contains(e.target)) return;

      this.setState({ menuDesktopLoggedStatus: false })
   }

   async logOut(e) {
      e.preventDefault();

      await axios.post('/api/logout')
         .then(res => console.log(res))
         .catch(err => console.log(err));

      window.location.reload();
   }


   componentDidUpdate(prevProps, prevState) {
      // only update chart if the data has changed
      if (prevProps.settings !== this.props.settings) {
         this.setState({ LOGGED: this.props.settings.userLogged });
      }
   }



   render() {

      const { style, user, settings } = this.props;
      const { menuMobileStatus, menuDesktopLoggedStatus, LOGGED } = this.state;

      //userType
      let userType
      let userSwitch

      if (user) {
         if (settings.currentUsername == user.username) {
            userType = settings.userType
            userSwitch = settings.userType == 'Mentee' ? 'Mentor' : 'Mentee'
         } else {
            userType = 'Mentee'
            userSwitch = 'Mentor'
         }
      }

      return (
         <nav className={classnames({
            "Navigation": true,
            [style]: style ? true : false,
            'trlGlobal': true,
            'loggedOut': !LOGGED,
            'logged': LOGGED,
         })}>
            <div className="content">

               <Link to="/"><img width="150" src={Logo} alt="Mentory.me" /></Link>

               <ul>
                  {
                     LOGGED ?
                        /* To logged users */
                        <React.Fragment>
                           {
                              userType == 'Mentee' ?
                                 <li><Link className="trl logged findMentor" to="/find">Find a mentor</Link></li>
                                 :
                                 null
                           }
                           <li className={classnames({
                              'notification': settings.notifications.length > 0,
                           })}><Link to="/connect" className="trl logged connect">Connect</Link></li>

                           {
                              /* Ya soy mentor? */
                              user && user.myListings.length == 0 ?
                                 <li><Link className="trl logged becomeMentor" to="/become-a-mentor">Become a mentor</Link></li>
                                 :
                                 null
                           }

                           <li ref={node => this.btnToggleMenuDesktopLogged = node} className="liUserLogged">
                              <div onClick={this._btnMenuDesktopLoggedToggle} className="userLogged">
                                 <span style={{ backgroundImage: `url("${user.userPhoto.trim() != '' ? user.userPhoto : defaultUser}")` }} className="photo"></span>
                                 <div>
                                    <p className="name">{this.props.user.name} {this.props.user.surname}</p>
                                    <span className="userType">{userType}</span>
                                 </div>
                                 <IoIosArrowDown />
                              </div>

                              <ul className={menuDesktopLoggedStatus ? 'active' : ''}>

                                 {
                                    /* Switch only available if you have a list */
                                    user && user.myListings.length > 0 ?
                                       <li onClick={() => {
                                          Store.dispatch(SETTINGS('userType', { userSwitch, username: user.username }))
                                          this._btnMenuDesktopLoggedToggle()
                                       }}><a><MdSwapCalls size="22" /><span className="trl logged useAs">Use as</span>&nbsp;{userSwitch}</a></li>
                                       :
                                       null
                                 }

                                 {
                                    /* Add listing only available if you have a list */
                                    user && user.myListings.length > 0 ?
                                       userType == 'Mentor' ?
                                          <li><Link to="/create-listing"><FiPlus size="24" /><span className="trl logged addListing">Add listing</span></Link></li>
                                          :
                                          null
                                       :
                                       <li><Link to="/become-a-mentor"><FiUsers size="22" /><span className="trl logged becomeMentor">Become a mentor</span></Link></li>
                                 }



                                 <li><Link to="/my-listings"><FiList size="22" /> <span className="trl logged myListings">My listings</span></Link></li>
                                 <li><Link to="/sessions"><FiBookOpen size="22" /> <span className="trl logged sessions">Sessions</span></Link></li>
                                 <li><Link to={`/${this.props.settings.publicProfile}/${this.props.user.username}`}><FiUser size="22" /> <span className="trl logged publicProfile">Public profile</span></Link></li>
                                 <li><Link to="/settings"><FiSettings size="22" /> <span className="trl logged settings">Settings</span></Link></li>

                                 <li onClick={this.logOut}><a><FiLogOut size="22" /> <span className="trl logged logOut">Log out</span></a></li>
                              </ul>
                           </li>
                        </React.Fragment>
                        :
                        /* To logged out users */
                        <React.Fragment>
                           <li><Link className="trl loggedOut findMentor" to="/find">Find a mentor</Link></li>
                           <li><Link className="trl loggedOut becomeMentor" to="/become-a-mentor">Become a mentor</Link></li>
                           <li><Link className="trl loggedOut login" to="/login">Login</Link></li>
                           <li><Link to="/signup"><button className="btn white trl loggedOut signUp">SIGN UP FOR FREE</button></Link></li>
                        </React.Fragment>
                  }

               </ul>

               <span onClick={this._btnMenuMobileOpen} className="btnMenu"><MdMenu size="40" /></span>

            </div>

            <nav className={classnames({
               "MenuMobile": true,
               "active": menuMobileStatus
            })}>
               {
                  LOGGED ?
                     <div className="logged">
                        <main>
                           <Link to="/"><img width="150" src={Logo} alt="" /></Link>
                           <div onClick={() => this.setState({ menuMobileStatus: false })}>
                              <MdClose size="25" />
                           </div>
                        </main>

                        {/* Global Links */}
                        <div onClick={this._btnMenuDesktopLoggedToggle} className="userLogged">
                           <span style={{ backgroundImage: `url(${defaultUser})` }} className="photo"></span>
                           <div>
                              <p className="name">{this.props.user.name} {this.props.user.surname}</p>
                              <span className="userType">{userType}</span>
                           </div>
                        </div>
                        <ul>
                           {
                              userType == 'Mentee' ?
                                 <li><Link to="/find" className="trl logged findMentor">Find a mentor</Link></li>
                                 :
                                 null
                           }

                           {
                              /* Ya soy mentor? */
                              user && user.myListings.length == 0 ?
                                 <li><Link className="trl logged becomeMentor" to="/become-a-mentor">Become a mentor</Link></li>
                                 :
                                 null
                           }

                           <li className={classnames({
                              'notification': settings.notifications.length > 0,
                           })}><Link to="/connect" className="trl logged connect">Connect</Link></li>

                           <li onClick={() => {
                              Store.dispatch(SETTINGS('userType', { userSwitch, username: user.username }))
                              this._btnMenuMobileLoggedToggle()
                           }}><a><MdSwapCalls size="22" /><span className="trl logged useAs">Use as</span>&nbsp;{userSwitch}</a></li>

                           {
                              userType == 'Mentor' ?
                                 <li><Link to="/create-listing"><FiPlus size="24" /> <span className="trl logged addListing">Add listing</span></Link></li>
                                 :
                                 null
                           }

                           <li><Link to="/my-listings"><FiList size="22" /> <span className="trl logged myListings">My listings</span></Link></li>
                           <li><Link to="/sessions"><FiBookOpen size="22" /> <span className="trl logged sessions">Sessions</span></Link></li>
                           <li><Link to={`/${this.props.settings.publicProfile}/${this.props.user.username}`}><FiUser size="22" /> <span className="trl logged publicProfile">Public profile</span></Link></li>
                           <li><Link to="/settings"><FiSettings size="22" /> <span className="trl logged settings">Settings</span></Link></li>

                           <li onClick={this.logOut}><a><FiLogOut size="22" /> <span className="trl logged logOut">Log out</span></a></li>
                        </ul>
                     </div>

                     :

                     <div className="loggedOut">
                        <main>
                           <Link to="/"><img width="150" src={Logo} alt="" /></Link>
                           <div onClick={() => this.setState({ menuMobileStatus: false })}>
                              <MdClose size="25" />
                           </div>
                        </main>

                        {/* Global Links */}
                        <ul>
                           <li><Link to="/" className="trl loggedOut home">Home</Link></li>
                           <li><Link to="/find" className="trl loggedOut findMentor">Find a mentor</Link></li>
                           <li><Link to="/become-a-mentor" className="trl loggedOut becomeMentor">Become a mentor</Link></li>
                        </ul>

                        <div className="actions">
                           <Link to="/login" className="trl loggedOut login">Login</Link>
                           <Link to="/signup"><button className="btn white trl loggedOut signUp">SIGN UP FOR FREE</button></Link>
                        </div>
                     </div>
               }
            </nav>

         </nav>
      )
   }
}


const mapStateToProps = state => {
   return state;
}

export default compose(withRouter, connect(mapStateToProps))(Menu);
