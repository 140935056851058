import React, { Component } from 'react';

import { MdClose } from "react-icons/md";

import classnames from 'classnames';

import "./sass/Popup.scss";


const StylePopup = {
   background: "#F6F5FA",
   zIndex: 99999999999999999999,
   position: "fixed",
   top: 0,
   left: 0,
   overflowY: "scroll",
   overflowX: "hidden",
   height: "100vh",
   width: "100%"
}

const StyleBtnCloseContainer = {
   display: "flex",
   justifyContent: "flex-end",
   marginRight: "5px",
   marginTop: "5px",
   marginBottom: "10px"
}
const StyleBtnClose = {
   padding: "5px", 
   cursor: "pointer", 
   background: "transparent",
   border: "none"
}



class Popup extends Component{
   constructor(props){
      super();

      this.state = {
         popupActive: false,
         updateClass: false,
         popupOpen: props.open
      }

      this.updatePopupActive = this.updatePopupActive.bind(this);
      this.closePopup = this.closePopup.bind(this);
   }

   updatePopupActive(){
      const { updateClass } = this.state;
      const { popupActiveWhen } = this.props;

      let width = window.innerWidth;
         
      if( width <= popupActiveWhen ){
         if( !updateClass ){

            this.setState({
               popupActive: true,
               updateClass: true
            })
         }
      }else{
         if( updateClass ){

            document.querySelector('body').className = '';

            this.setState({
               popupActive: false,
               updateClass: false
            })
         }
      }
   }


   componentWillReceiveProps(props){
      this.setState({popupOpen: props.open})
   }

   closePopup(){
      document.querySelector('body').className = '';

      this.setState({popupOpen: false})
   }

   componentDidMount(){
      this.updatePopupActive()
      window.addEventListener('resize', this.updatePopupActive)
   }

   render(){

      const { children } = this.props;
      const { popupActive, popupOpen } = this.state;

      return(
         <div style={ popupActive ? StylePopup : null } className={classnames({
            "Popup": true,
            "opened": popupOpen,
            "mobile": popupActive
         })}>
            {
               popupActive ?
                  <div style={StyleBtnCloseContainer}>
                     <button onClick={this.closePopup} style={StyleBtnClose}><MdClose size="25" /></button>
                  </div>
                  :
                  null
            }
            <div className="contentPopupComponent">
               {children}
            </div>
         </div>
         
      )
   }
}

export default Popup;