import io from 'socket.io-client';

//Redux
import Store from '../redux/store';
import { SETTINGS, UPDATE_USER } from '../redux/actions';


const Socket = process.env.NODE_ENV == 'production' ? io() : io('http://localhost:3000');


//if( Socket === undefined ) throw "Something went worng with SocketIO client. Socket variable is = undefined";


Socket.on('reconnect', function () {
   Socket.emit('logged_users', Store.getState().user);
});


Socket.on('watch_update_user_model', userObject => {
   try {
      if (Store.getState().user.username != userObject.username) return;
      Store.dispatch(UPDATE_USER(userObject))
   } catch (error) {}
})

function watchChangesStatusFromAllUsers() {
   Socket.on('watch_change_status_user', nicknames => {
      Store.dispatch(SETTINGS('allUsersStatus', nicknames));
   })
}

function getCurrentStatusFromAllUsers() {
   Socket.emit('get_current_state', nicknames => {
      Store.dispatch(SETTINGS('allUsersStatus', nicknames));
   })
}

export { Socket, watchChangesStatusFromAllUsers, getCurrentStatusFromAllUsers };