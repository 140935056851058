import React, { Component } from 'react';

import './sass/Session.scss';

//Component
import Nav from '../../components/Menu';
import Footer from '../../components/Footer';
import Separator from '../../UI_Elements/Separator';
//Mentor
import ResponsePendingMentor from '../../components/SessionsCard/ResponsePendingMentor';
import ActiveSessionMentor from '../../components/SessionsCard/ActiveSessionMentor';
import CompletedSessionMentor from '../../components/SessionsCard/CompletedSessionMentor';
//Mentee
import ResponsePendingMentee from '../../components/SessionsCard/ResponsePendingMentee';
import ActiveSessionMentee from '../../components/SessionsCard/ActiveSessionMentee';
import CompletedSessionMentee from '../../components/SessionsCard/CompletedSessionMentee';

import Modal from '../../UI_Elements/Modal';
import Input from '../../UI_Elements/Form/Input';
import InputDate from '../../UI_Elements/Form/InputDate';
import RadioButton from '../../UI_Elements/Form/RadioButton';
import ReportUser from '../../components/ReportUser';


//Assets
import mentorPhoto from '../../assets/mentor.png';
import defaultPhoto from '../../assets/default.png';
import StarRatings from '../../UI_Elements/StarRatings';

import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

import ReactSlick from "react-slick";

//Redux
import { connect } from 'react-redux';
import Store from '../../redux/store';
import { UPDATE_USER } from '../../redux/actions';

import moment from 'moment-timezone';

import axios from 'axios';

import Money from '../../inc/Money';

import Preloader from '../../UI_Elements/Preloader';

import Response from '../../UI_Elements/Response';
import showResponse from '../../UI_Elements/Response/showResponse';

import OpenDispute from '../../components/OpenDispute';

import EndRateSession from '../../components/EndRateSession';


class Session extends Component{
   constructor(){
      super();


      this.state = {
         dateEditRq: null,
         currentMenteeSessionSelected: null,
         currentMentorSessionSelected: null,
         currentUserSession: null,

         //Btn
         updateUserSent: false,

         //Preload
         declineFormSet: false,
         acceptSessionFormSent: false,
      }

      this.manageRequestModal = React.createRef();
      this.EditRequestModal = React.createRef();
      this.reportUserModal = React.createRef();
      this.refModalOpenDispute = React.createRef();
      this.declineSessionModal = React.createRef();
      this.whyEditRq = React.createRef();

      //Decline
      this.whyDeclineSessionCheckbox = React.createRef();
      this.whyDeclineSessionText = React.createRef();

      this.handlerClickMangeRequestModal = this.handlerClickMangeRequestModal.bind(this);
      this.handlerClickEditRequest = this.handlerClickEditRequest.bind(this);
      this.handleClickReportUser = this.handleClickReportUser.bind(this);
      this.handleClickOpenDispute = this.handleClickOpenDispute.bind(this);
      this.handleClickDeclineSession = this.handleClickDeclineSession.bind(this);
      this.acceptSession = this.acceptSession.bind(this);
      this.EDIT_REQUEST = this.EDIT_REQUEST.bind(this);
      this.declineSession = this.declineSession.bind(this);

      //End&Rate
      this.endRateSessionModal = React.createRef();
   }

   handlerClickMangeRequestModal(session){
      this.setState({currentMentorSessionSelected: session})
      this.manageRequestModal.current._openCloseModal(true);
   }
   handlerClickEditRequest(session){
      this.setState({currentMenteeSessionSelected: session})
      this.EditRequestModal.current._openCloseModal(true);
   }
   handleClickReportUser(session){
      this.setState({currentUserSession: session})
      this.reportUserModal.current._openCloseModal(true);
   }
   handleClickOpenDispute(session){
      this.setState({currentUserSession: session})
      this.refModalOpenDispute.current._openCloseModal(true);
   }
   handleClickDeclineSession(){  
      this.manageRequestModal.current._openCloseModal(false);
      this.declineSessionModal.current._openCloseModal(true);
   }


   acceptSession(){
      const { currentMentorSessionSelected } = this.state;

      this.setState({acceptSessionFormSent: true})


      axios.put('/api/manage-request', 
         {
            currentMentorSessionSelected, 
            menteeUsername: currentMentorSessionSelected.menteeUsername, 
            mentorUsername: currentMentorSessionSelected.mentorUsername
         })
         .then(res => {
            Store.dispatch( UPDATE_USER(res.data) )

            this.manageRequestModal.current._openCloseModal(false);

            this.setState({acceptSessionFormSent: false})
         })
         .catch(err => {
            console.log(err)
            console.log(err.response)

            this.setState({acceptSessionFormSent: false})
         })
   }
   declineSession(e){
      e.preventDefault();

      this.setState({declineFormSet: true})

      const { currentMentorSessionSelected } = this.state;

      const whyDeclineSessionCheckbox = this.whyDeclineSessionCheckbox.current.state.valueSelected;
      const whyDeclineSessionText = this.whyDeclineSessionText.current.state.inputValue;

      if( whyDeclineSessionCheckbox.trim() == '' || whyDeclineSessionText.trim() == '' ){
         showResponse('DeclineSessionError', 'All fields is required.', 'All fields is required.')
         return this.setState({declineFormSet: false});
      }

      axios.post('/api/manage-request/decline-session', 
         {
            currentMentorSessionSelected, 
            whyRejected: `${whyDeclineSessionCheckbox} ${whyDeclineSessionText}`
         })
         .then(res => {
            this.declineSessionModal.current._openCloseModal(false);

            showResponse('DeclineSessionSuccess');

            Store.dispatch( UPDATE_USER(res.data) )
            this.setState({declineFormSet: false})
         })
         .catch(err => {
            showResponse('DeclineSessionError', 'Ops! Something went wrong', 'Something went wrong')

            console.log(err)
            console.log(err.response)
            this.setState({declineFormSet: false})
         })
   }

   EDIT_REQUEST(e){
      e.preventDefault();

      //load
      this.setState({updateUserSent: true})

      const { currentMenteeSessionSelected } = this.state;

      const dateEditRq = this.state.dateEditRq;
      const whyEditRq = this.whyEditRq.current.state.inputValue;


      axios.patch('/api/manage-request', {dateEditRq, whyEditRq, currentMenteeSessionSelected})
         .then(res => {
            console.log(res)
            this.setState({updateUserSent: false})

            showResponse('EditRequestSuccess');
            this.EditRequestModal.current._openCloseModal(false);
         })
         .catch(err => {
            console.log(err)

            if( err.response.status == 401 ){
               showResponse('EditRequestError401', err.response.data);
            }else{
               showResponse('EditRequestError', err.response.data);
            }
            this.setState({updateUserSent: false})
         })

   }


   render(){

      const { dateEditRq, currentMenteeSessionSelected, currentMentorSessionSelected, currentUserSession } = this.state;
      const { updateUserSent } = this.state;
      const { user, settings } = this.props;

      let validUserType
      if( settings.currentUsername == user.username ){
         validUserType = settings.userType
      }else{
         validUserType = 'Mentee'
      }


      const Settings = {
         dots: true,
         arrow: true,
         infinite: true,
         speed: 500,
         slidesToScroll: 1,
         nextArrow: <button><MdKeyboardArrowRight size="35" /></button>,
         responsive: [
           {
             breakpoint: 900,
             settings: {
               slidesToShow: 1,
             }
           },
   
         ]
      };


      const DateEditRq = new Date();
      DateEditRq.setDate( DateEditRq.getDate() + 20 );

      //Filter session
      const sessionsMenteeFiltered = {pending: [], active: [], completed: []}
      user.mySessions.asMentee.map(session => {
         if( session.status == 'pending' ){
            sessionsMenteeFiltered.pending.push(session)
         }else if( session.status == 'active' ){
            sessionsMenteeFiltered.active.push(session)
         }else if( session.status == 'completed' || session.status == 'disputed' ){
            sessionsMenteeFiltered.completed.push(session)
         }
      });

      const sessionsMentorFiltered = { pending: [], active: [], completed: [] }
      user.mySessions.asMentor.map(session => {
         if( session.status == 'pending' ){
            sessionsMentorFiltered.pending.push(session)
         }else if( session.status == 'active' ){
            sessionsMentorFiltered.active.push(session)
         }else if( session.status == 'completed' || session.status == 'disputed' ){
            sessionsMentorFiltered.completed.push(session)
         }
      });


      return(
         <div id="Session">

            <EndRateSession user={this.props.user} ref={this.endRateSessionModal} />

            {/* Responses */}
            <Response 
               id="EditRequestError"
               type="error"
               title="All fields is required"
               description=""
               btnText="Close"
               btnAction="close"
            />
            <Response 
               id="EditRequestError401"
               type="error"
               title="Something went wrong"
               description=""
               btnText="Close"
               btnAction="close"
            />
            <Response 
               id="EditRequestSuccess"
               type="success"
               title="Session request updated"
               description="Your Mentor was notified and will reply soon."
               btnText="Close"
               btnAction="close"
            />

            <Response 
               id="DeclineSessionSuccess"
               type="success"
               title="Session declined"
               description="Session declined."
               btnText="Close"
               btnAction="close"
            />
            <Response 
               id="DeclineSessionError"
               type="error"
               title=""
               description=""
               btnText="Close"
               btnAction="close"
            />
            

            <Nav style="stylish" />

            <section>
               <div className="content">

                  <div className="Profile flex">
                     <span style={{backgroundImage: `url(${user.userPhoto.trim() != '' ? user.userPhoto : defaultPhoto})`}} className="photo"></span>
                     <div>
                        <h4>{user.name} {user.surname}</h4>
                        <p className="location">{user.location}</p>

                        <p className="trl sessions rating">Rating:</p>
                        {
                           user.totReviews > 0 ?
                              <StarRatings rating={(user.rating / user.totReviews)} />
                              :
                              <small><i className="trl sessions noReviewsYet">No reviews yet</i></small>
                        }

                        <div className="aboutMe">
                           <p className="trl sessions aboutMe">About me</p>
                           <p>{user.bio}</p>
                        </div>
                     </div>
                  </div>

                  <Separator className="trl sessions responsePending" title="Response pending" />
                  <div className="box responsePending">
                     {
                        validUserType == 'Mentor' ?
                           <ReactSlick {...Settings} slidesToShow={sessionsMentorFiltered.pending.length < 2 ? 1 : 2}>
                              {
                                 sessionsMentorFiltered.pending.map((session, i) => {
                                    return <ResponsePendingMentor 
                                             key={i}
                                             onClickReportUser={() => this.handleClickReportUser(session)} 
                                             onClickMangeRequest={() => this.handlerClickMangeRequestModal(session)} 
                                             session={session}
                                          />
                                 })
                              }
                           </ReactSlick>

                           :

                           <ReactSlick {...Settings} slidesToShow={sessionsMenteeFiltered.pending.length < 2 ? 1 : 2}>
                              {
                                 sessionsMenteeFiltered.pending.map((session, i) => {
                                    return <ResponsePendingMentee 
                                             key={i} 
                                             onClickReportUser={() => this.handleClickReportUser(session)} 
                                             onClickEditRequest={() => this.handlerClickEditRequest(session)}
                                             session={session} 
                                          />
                                 })
                              }
                           </ReactSlick>
                     }
                  </div>

                  <Separator className="trl sessions activeSessions" title="Active sessions" />
                  <div className="box activeSessions">

                     {
                        validUserType == 'Mentor' ?
                           <ReactSlick {...Settings} slidesToShow={sessionsMentorFiltered.active.length < 2 ? 1 : 2}>
                              {
                                 sessionsMentorFiltered.active.map((session, i) => {
                                    return <ActiveSessionMentor 
                                             session={session} 
                                             key={i} 
                                             onClickReportUser={() => this.handleClickReportUser(session)}
                                             onClickOpenDispute={() => this.handleClickOpenDispute(session)} 
                                          />
                                 })
                              }
                           </ReactSlick>

                           :

                           <ReactSlick {...Settings} slidesToShow={sessionsMenteeFiltered.active.length < 2 ? 1 : 2}>
                              {
                                 sessionsMenteeFiltered.active.map((session, i) => {
                                    return <ActiveSessionMentee 
                                             key={i} 
                                             session={session} 
                                             onClickReportUser={() => this.handleClickReportUser(session)}
                                             onClickOpenDispute={() => this.handleClickOpenDispute(session)} 
                                          />
                                 })
                              }
                           </ReactSlick>
                     }
                  </div>

                  <Separator className="trl sessions completedSessions" title="Completed sessions" />
                  <div className="box completedSessions">
                     {
                        validUserType == 'Mentor' ?
                           <ReactSlick {...Settings} slidesToShow={sessionsMentorFiltered.completed.length < 2 ? 1 : 2}>
                              {
                                 sessionsMentorFiltered.completed.map((session, i) => {
                                    return <CompletedSessionMentor 
                                             session={session} 
                                             key={i} 
                                             onClickReportUser={() => this.handleClickReportUser(session)} 
                                             onClickOpenDispute={() => this.handleClickOpenDispute(session)}
                                          />
                                 })
                              }
                           </ReactSlick>

                           :

                           <ReactSlick {...Settings} slidesToShow={sessionsMenteeFiltered.completed.length < 2 ? 1 : 2}>
                              {
                                 sessionsMenteeFiltered.completed.map((session, i) => {
                                    return <CompletedSessionMentee 
                                       endRateSession={this.endRateSessionModal}
                                       key={i} 
                                       session={session}  
                                       onClickReportUser={() => this.handleClickReportUser(session)} 
                                       onClickOpenDispute={() => this.handleClickOpenDispute(session)}
                                    />
                                 })
                              }
                           </ReactSlick>
                     }
                  </div>

               </div>
            </section>
            <Footer />





            <Modal title="Manage request" className="ManageRequestModal" ref={this.manageRequestModal} iconToClose>
               {
                  currentMentorSessionSelected ?
                     <div>
                        <span style={{backgroundImage: `url(${currentMentorSessionSelected.menteeObject.userPhoto.trim() != '' ? currentMentorSessionSelected.menteeObject.userPhoto : defaultPhoto})`}} className="photo"></span>
                        <h4>{currentMentorSessionSelected.titleListing}</h4>
                        <p className="menteeName">{currentMentorSessionSelected.menteeObject.name} {currentMentorSessionSelected.menteeObject.surname}</p>

                        <div>
                           <p className="trl sessions manageRequest question helpWith">What your mentee needs help with</p>

                           {
                              //Mentee edit request?????
                              currentMentorSessionSelected.requestsEdited.length != 0 ?
                                 <p className="answer">{currentMentorSessionSelected.requestsEdited[ currentMentorSessionSelected.requestsEdited.length-1].why}</p>
                                 :
                                 <p className="answer">{currentMentorSessionSelected.helpDescription}</p>
                           }
                        </div>

                        <div>
                           <p className="trl sessions manageRequest question sessionLength">Session lenght</p>
                           <p className="answer">{currentMentorSessionSelected.sessionLength} minutes</p>
                        </div>

                        <div>
                           <p className="trl sessions manageRequest question timeTheySuggestToTalk">Time they suggest to talk at</p>
                           {
                              //Mentee edit request?????
                              currentMentorSessionSelected.requestsEdited.length != 0 ?
                                 <p className="answer">{moment(currentMentorSessionSelected.requestsEdited[currentMentorSessionSelected.requestsEdited.length-1].newDate).format('dddd DD MMMM, h:mm A')}</p>
                                 :
                                 <p className="answer">{moment(currentMentorSessionSelected.mentorDate).format('dddd DD MMMM')}, {currentMentorSessionSelected.mentorTime}</p>
                           }
                           <p className="trl sessions manageRequest description">You can chat and agree to a different date and time on Connect, once you’ve accepted this request.</p>
                        </div>

                        <div className="noBorder">
                           <p className="trl sessions manageRequest question payout">Payout</p>
                           <p className="answer noBorder"><span className="trl sessions manageRequest youWillMake">You will make</span> <strong><Money value={(currentMentorSessionSelected.price.mentor/100)} currency={this.props.user.currency} /></strong></p>
                        </div>

                        <div className="actions flex noBorder">
                           {
                              this.state.acceptSessionFormSent ?
                                 <Preloader />
                                 :
                                 <button onClick={this.acceptSession} className="btn trl sessions manageRequest accept">accept</button>
                           }
                           <p className="trl sessions manageRequest decline" onClick={this.handleClickDeclineSession}>decline</p>
                        </div>
                     </div>
                     :
                     null
               }
            </Modal>
                  
            <Modal ref={this.declineSessionModal} title="Decline session" className="DeclineSession" iconToClose>
               {
                  currentMentorSessionSelected ?
                     <div>
                        <span style={{backgroundImage: `url(${currentMentorSessionSelected.menteeObject.userPhoto.trim() != '' ? currentMentorSessionSelected.menteeObject.userPhoto : defaultPhoto})`}} className="photo"></span>
                        <h4>{currentMentorSessionSelected.titleListing}</h4>
                        <p className="menteeName">{currentMentorSessionSelected.menteeObject.name} {currentMentorSessionSelected.menteeObject.surname}</p>

                        <form onSubmit={this.declineSession} className="simple">
                           <RadioButton 
                              ref={this.whyDeclineSessionCheckbox}
                              type="v" 
                              title={`Why are you declining ${currentMentorSessionSelected.menteeObject.name}'s connection request?`} 
                              //values={['sessions opt1', 'sessions opt2', 'sessions opt3', 'sessions opt4', 'sessions opt5']}
                              labels={["My expertise doesn’t meet the mentee’s needs.", "I’m umconfortable with this mentee.", "It will require more time of what I can afford.", "Requesting to pay or connect outside of Mentory.", "Something else."]} 
                           />
                           <Input id="whyDeclineSessionText" ref={this.whyDeclineSessionText} textarea label="Type a decline message for the user*" placeholder="Message" />

                           <div className="actions flex">
                              {
                                 this.state.declineFormSet ?
                                    <Preloader />
                                    :
                                    <button type="submit" className="btn">decline</button>
                              }
                              
                              <p onClick={() => this.declineSessionModal.current._openCloseModal(false)}>cancel</p>
                           </div>
                        </form>
                     </div>
                     :
                     null
               }
            </Modal>


            {
               validUserType == 'Mentee' ?
                  <Modal title="Edit request" className="EditRequestModal" ref={this.EditRequestModal} iconToClose>
                     {
                        currentMenteeSessionSelected ?
                           <div>
                              <span style={{backgroundImage: `url(${currentMenteeSessionSelected.mentorObject.userPhoto.trim() != '' ? currentMenteeSessionSelected.mentorObject.userPhoto : defaultPhoto})`}} className="photo"></span>
                              <h4>{currentMenteeSessionSelected.titleListing}</h4>
                              <p className="menteeName">{currentMenteeSessionSelected.mentorObject.name} {currentMenteeSessionSelected.mentorObject.surname}</p>

                              <form onSubmit={this.EDIT_REQUEST} className="simple">
                                 <Input 
                                    ref={this.whyEditRq} 
                                    textarea 
                                    label="Let the Mentor know why are you editing this request" 
                                    placeholder={currentMenteeSessionSelected.requestsEdited.length != 0 ? currentMenteeSessionSelected.requestsEdited[currentMenteeSessionSelected.requestsEdited.length-1].why : currentMenteeSessionSelected.helpDescription}
                                 />
                                 <InputDate 
                                    label="Choose another time that works you or you Mentor" 
                                    icon={<IoIosArrowDown />}
                                    selected={dateEditRq}
                                    onSelect={newDate => this.setState({dateEditRq: newDate})}
                                    placeholder={currentMenteeSessionSelected.requestsEdited.length != 0 ? moment(currentMenteeSessionSelected.requestsEdited[currentMenteeSessionSelected.requestsEdited.length-1].newDate).format('dddd DD MMMM, h:mm A') : moment(currentMenteeSessionSelected.menteeDate + ' ' + currentMenteeSessionSelected.menteeTime).format('dddd DD MMMM, h:mm A') }
               
                                    time
                                    minDate={new Date()}
                                    maxDate={DateEditRq}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    className="InputDateEditRequest"
                                 />
               
                                 <div className="actions flex">
                                    {
                                       updateUserSent ?
                                          <Preloader />
                                          :
                                          <button className="btn">update request</button>
                                    }
                                    
                                    <p onClick={() => this.EditRequestModal.current._openCloseModal(false)}>cancel</p>
                                 </div>
                              </form>
                           </div>
                           :
                           null

                     }
                  </Modal>
                  :
                  null
            }



            {
               currentUserSession ?
                  <ReportUser 
                     userPhoto={currentUserSession['menteeObject'] ? currentUserSession['menteeObject'].userPhoto : currentUserSession['mentorObject'].userPhoto} 
                     name={currentUserSession['menteeObject'] ? currentUserSession['menteeObject'].name : currentUserSession['mentorObject'].name} 
                     surname={currentUserSession['menteeObject'] ? currentUserSession['menteeObject'].surname : currentUserSession['mentorObject'].surname} 
                     username={currentUserSession['menteeObject'] ? currentUserSession['menteeObject'].username : currentUserSession['mentorObject'].username}  
                     titleListing={currentUserSession.titleListing}
                     refModal={this.reportUserModal} 
                  />
                  :
                  <ReportUser 
                     refModal={this.reportUserModal} 
                  />
            }

            {
               currentUserSession ?
                  <OpenDispute 
                     userPhoto={currentUserSession['menteeObject'] ? currentUserSession['menteeObject'].userPhoto : currentUserSession['mentorObject'].userPhoto} 
                     name={currentUserSession['menteeObject'] ? currentUserSession['menteeObject'].name : currentUserSession['mentorObject'].name} 
                     surname={currentUserSession['menteeObject'] ? currentUserSession['menteeObject'].surname : currentUserSession['mentorObject'].surname} 
                     username={currentUserSession['menteeObject'] ? currentUserSession['menteeObject'].username : currentUserSession['mentorObject'].username}  
                     titleListing={currentUserSession.titleListing}
                     id={currentUserSession._id}
                     refModal={this.refModalOpenDispute} 
                  />
                  :
                  <OpenDispute 
                     refModal={this.refModalOpenDispute} 
                  />
            }

         </div>
      )
   }
}


const mapStateToProps = state => {
   return state;
} 
export default connect(mapStateToProps)(Session);