import React from 'react';

import classnames from 'classnames';

//SASS
import './sass/FAQ.scss';


import { FiPlus, FiMinus } from "react-icons/fi";

class FAQ extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         statusCloseOpened: false
      }

      this.openCloseThisFaq = this.openCloseThisFaq.bind(this);
   }

   openCloseThisFaq(){
      this.setState({statusCloseOpened: !this.state.statusCloseOpened});
   }
  
   render() {

      const { statusCloseOpened } = this.state;
      const { question, answer, value } = this.props;

      return (
         <div className={classnames({
            'QuestionFAQ': true,
            'opened': statusCloseOpened
         })}>
            <div onClick={this.openCloseThisFaq} className="title">
               <p className={`trl becomeMentor faq title${value}`}>{question}</p>
               <span className="icon">
                  {
                     statusCloseOpened ?
                        <span style={{color: "#4663F6"}}><FiPlus size="25" /></span>
                        :
                        <FiMinus size="25" />
                  }
               </span>
               
            </div>
            <div className="answer">
               <p className={`trl becomeMentor faq text${value}`}>{answer}</p>
            </div>
         </div>
      );
   }
}

export default FAQ;