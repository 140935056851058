import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import axios from 'axios';
import Store from '../redux/store'
import { SETTINGS } from '../redux/actions'

import Preloader from '../UI_Elements/Preloader';

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }

    componentDidMount() {

      axios.get('/api/checkToken', {onlyCheckToken: true})
        .then(res => {
          if (res.status === 200) {
            this.setState({ loading: false });
          } else {
            console.log('no deberia entrar aqui...')
            console.log("checkToken error: ", res);
            console.log('response: ', res.response)
            //this.setState({ loading: false, redirect: true });
          }
        })
        .catch(err => {
          this.setState({ loading: false, redirect: true })
          //Store.dispatch( SETTINGS('userLogged', false) )
        });
    }


    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return <Preloader size="big" page />;
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment>
      );
    }
  }
}