import React, { Component } from 'react'

import './sass/Pricing.scss'

import Nav from '../../components/Menu'
import Footer from '../../components/Footer'

import FAQ from '../../UI_Elements/FAQ'

//Lazy load
import LazyLoad from 'react-lazyload'

import HowItWorks_1 from '../../assets/home/how_it_works/img1.png';
import HowItWorks_2 from '../../assets/home/how_it_works/img2.png';
import HowItWorks_3 from '../../assets/home/how_it_works/img3.png';

import Effect3 from '../../assets/home/how_it_works/effect.svg';

import bkgFAQ from '../../assets/BecomeaMentor/bkg_faq.svg';

import Apple from '../../assets/Pricing/apple.svg'
import HubSpot from '../../assets/Pricing/hubspot.svg'
import Pepsico from '../../assets/Pricing/pepsico.svg'

import Photo1 from '../../assets/Pricing/photo1.png'
import Photo2 from '../../assets/Pricing/photo2.png'

import Career_effect_2 from '../../assets/Pricing/career_effect_2.svg'
import Effect_header_1 from '../../assets/Pricing/effect_header_1.svg'
import Effect_header_2 from '../../assets/Pricing/effect_header_2.svg'

import { Link } from "react-router-dom"

import { connect } from 'react-redux'


class Pricing extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div id="Pricing">
                <img id="effect_header_1" src={Effect_header_1} alt="Effect header" />

                <Nav style="stylish2" />

                <section id="header">
                    <div className="content">
                        <h1 className="trl pricing title">Professional mentors to help you move forward in your career and life.</h1>
                        <h2 className="trl pricing subtitle">Simple and transparent pricing.</h2>
                        <div className="plan">
                            <div className="monthlyPlan free">
                                <p className="trl pricing freePlan">Free plan</p>
                                <div>
                                    <p>
                                        <span className="title trl pricing plan free">FREE</span>
                                    </p>
                                </div>
                                <ul>
                                    <li className="trl pricing plan freePlan l1">Browse our mentors database in view mode.</li>
                                    <li className="trl pricing plan freePlan l2">Perfect for people who like to do the same thing at work again and again, while they complain about how difficult is to achieve anything and how they’ve no time to learn new things.</li>
                                </ul>
                                <Link to="/signup"><button className="btn trl pricing plan btn freePlan">free plan</button></Link>
                            </div>
                            <div className="monthlyPlan">
                                <p className="trl pricing paidPlan">Growth plan</p>
                                <div>
                                    <p>
                                        <span className="title">19,99€</span>
                                        <span className="des trl pricing plan perMouth">per month</span>
                                    </p>
                                </div>
                                <ul>
                                    <li className="trl pricing plan paidPlan l1">Unlimited access to our mentors database.</li>
                                    <li className="trl pricing plan paidPlan l2">Schedule calls with profesional coaches, mentors and experts.</li>
                                    <li className="trl pricing plan paidPlan l3">Unlimited free sessions with mentors listed as free.</li>
                                    <li className="trl pricing plan paidPlan l4">Mentors matchmaking directly from the founder.</li>
                                    <li className="trl pricing plan paidPlan l5">Network with other members in our private communities.</li>
                                </ul>
                                <Link to="/upgrade"><button className="btn trl pricing paidPlan btn getAccessNow">get access now</button></Link>
                            </div>
                        </div>
                    </div>

                    <img id="effect_header_2" src={Effect_header_2} alt="Effect header" />
                </section>

                <section id="career">
                    <div className="content">
                        <h1 className="trl pricing career title">You just found a better way to advance your career.</h1>

                        <div>
                            <div className="left">
                                <article>
                                    <span style={{ backgroundImage: `url(${Photo1})` }} className="photo"></span>
                                    <p className="trl pricing career left text1">Davy used Mentory to prepare for an important interview that  allowed him to get out of unemployment.</p>
                                </article>
                                <article>
                                    <img src={Career_effect_2} alt="Career_effect_2" />
                                    <p className="trl pricing career left text2">Maeva worked with her mentor to prepare and pass an interview at Nike, company that she’s always admired.</p>
                                    <span style={{ backgroundImage: `url(${Photo2})` }} className="photo"></span>
                                </article>
                            </div>
                            <div className="right">
                                <ul>
                                    <li className="trl pricing career list l1">Schedule calls with a curated collection of coaches, experts and thought leaders at affordable prices, or even for free.</li>
                                    <li className="trl pricing career list l2">Beyond being great professionals, we ensure that all our mentors are friendly and genuinely enjoy helping</li>
                                    <li className="trl pricing career list l3">Mentor profiles are full of details and contextual information so you can find the best fit for your needs.</li>
                                    <li className="trl pricing career list l4">Membership includes personal support and mentor’s matchmaking from the founder of Mentory to help you get the maximum value from the platform.</li>
                                    <li className="trl pricing career list l5">Talk to your mentors using our integrated video chat platform that allows you to use share your screens, exchange files and connect in a fast and secure manner.</li>
                                </ul>

                                <div>
                                    <p className="trl pricing career ourMentors">Our mentors have experience in companies like</p>
                                    <figure>
                                        <img src={Apple} alt="Apple" />
                                        <img src={HubSpot} alt="HubSpot" />
                                        <img src={Pepsico} alt="Pepsico" />
                                    </figure>
                                </div>
                            </div>
                            <div id="mobile">
                                <p className="trl pricing career ourMentors">Our mentors have experience in companies like</p>
                                <figure>
                                    <img src={Apple} alt="Apple" />
                                    <img src={HubSpot} alt="HubSpot" />
                                    <img src={Pepsico} alt="Pepsico" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="howItWorks">
                    <LazyLoad once height="200">
                        <img className="effect1" src={Effect3} alt="Effect" />
                    </LazyLoad>

                    <div className="content">
                        <h2 className="trl title howItWorks">How it works?</h2>
                        <p className="trl subtitle howItWorks">Growth. At your pace. Anywhere.</p>

                        <div className="flex">
                            <div>
                                <LazyLoad once height="150">
                                    <figure><img src={HowItWorks_1} alt="Plan" /></figure>
                                </LazyLoad>
                                <h3 className="trl plan title howItWorks">Plan</h3>
                                <p className="trl plan text howItWorks">Choose the skills that you’d like to get better at.</p>
                            </div>

                            <div>
                                <LazyLoad once height="150">
                                    <figure><img src={HowItWorks_2} alt="Find" /></figure>
                                </LazyLoad>
                                <h3 className="trl find title howItWorks">Find</h3>
                                <p className="trl find text howItWorks">Find a mentor who can help you with your goal and connect to book a session.</p>
                            </div>

                            <div>
                                <LazyLoad once height="150">
                                    <figure><img src={HowItWorks_3} alt="Learn" /></figure>
                                </LazyLoad>
                                <h3 className="trl learn title howItWorks">Learn</h3>
                                <p className="trl learn text howItWorks">Take your session with your mentor directly in Mentory via chat, audio or video.</p>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="faq">
                    <img src={bkgFAQ} alt="" />

                    <div className="content">
                        <h2 className="trl becomeMentor faq title">Popular questions</h2>

                        <article>
                            <div>
                                <FAQ value="1" question="I've never done anything like this. What should I expect?" answer="Having a mentor by your side means that you'll receive an objective and unbiased point of view about your career and professional development. Someone making sure you're going in the right direction and that you'll succeed. The more you put into your sessions, the more you'll get out." />
                                <FAQ value="2" question="Why do I've to pay to access to the platform?" answer="We're a bootstraped business and with your subscription fee, you're contributing to develop new features for our community and keep the idea alive. We reinvest 100% of your fees in Mentory." />
                                <FAQ value="3" question="How do you vet mentors?" answer="We manually recruit the best mentors for each area and conduct a background check and a personal call to make sure that they're knowledgeable, friendly and willing to help others." />
                                <FAQ value="4" question="Why some mentors are more expensive than others?" answer="All of our mentors have been vetted and are backed by Mentory, but they each offer something different. In addition to the experience of the mentors, some services offer you more time or interactions with your coach. These differences are listed on individual service items." />
                            </div>
                            <div>
                                <FAQ value="5" question="What do mentor services mean?" answer="Mentors can offer different services and we like to work with specialists in each one of the areas that we offer. Unike traditional learning systems, at Mentory we believe that one size doesn't fit all." />
                                <FAQ value="6" question="When do sessions happen?" answer="Once your mentor has confirmed your session, connect on the agreed day and time to talk with your mentor. You can type or start a video call with your mentor." />
                                <FAQ value="7" question="Can I cancel my membership?" answer="You can request a refund of your membership within the first 7 days of your subscription. We cannot refund it if you have messaged, booked or taken a session with a mentor." />
                            </div>
                        </article>

                        <a href="https://mentory.freshdesk.com/support/tickets/new" target="_blank">
                            <button className="btn white trl becomeMentor faq btnAction">more questions?</button>
                        </a>
                    </div>
                </section>

                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state
}
export default connect(mapStateToProps)(Pricing)