import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

export default descriptionRaw => {
   var listingDescription = stateToHTML( convertFromRaw( JSON.parse(descriptionRaw) ) );

   const regexFindUrlYoutube =  RegExp('(?:(https:\/\/|)(|www\.)youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})', 'gm');
   const regexFindUrlYoutubeInsideLink = RegExp('<a href="(?:(https:\/\/|)(|www\.)youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})">\/?[^>]*>', 'gm');

   try {
      if( regexFindUrlYoutube.test(listingDescription) ){
         const youtubeVideoURL = listingDescription.match(regexFindUrlYoutube)[0];
         const youtubeVideoID = youtubeVideoURL.match("v=([a-zA-Z0-9\_\-]+)&?")[1];
         
         if( regexFindUrlYoutubeInsideLink.test(listingDescription) ){
            return listingDescription.replace(regexFindUrlYoutubeInsideLink,
               `<div class="video-container">
                  <iframe src="https://www.youtube.com/embed/${youtubeVideoID}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                  </iframe>
               </div>`)
         }else{
            return listingDescription.replace(regexFindUrlYoutube,
               `<div class="video-container">
                  <iframe src="https://www.youtube.com/embed/${youtubeVideoID}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                  </iframe>
               </div>`)
         }
      }else{
         return listingDescription;
      }
   }catch(err) {
      return listingDescription;
   }
}