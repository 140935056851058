const animateCSS = require('../../inc/AnimateCSS');

function showResponse( id, dynamicText, dynamicTitle ){

   //dynamicText exist
   if( dynamicText ) document.querySelector(`.Response#${id} p`).innerHTML = dynamicText;
   if( dynamicTitle ) document.querySelector(`.Response#${id} h3`).innerHTML = dynamicTitle;

   const node = document.querySelector(`.Response#${id}`);
   node.style.display = 'block';

   animateCSS(`.Response#${id}`, 'fadeIn')
   animateCSS(`.Response#${id} .box`, 'zoomIn')
}

module.exports = showResponse;