const moment = require('moment-timezone');

export default function( mentorTimezone, menteeTimezone, dateMenteeSelected, timeMenteeSelected ){
  
   let dateMentee = moment( dateMenteeSelected ).format('MMMM Do YYYY');
   let MENTOR_DATE_AND_TIME = moment(`${dateMentee} ${timeMenteeSelected}`,'MMMM Do YYYY h:mm a').tz(mentorTimezone);


   //Diff tiezones
   let timezone = moment().tz(mentorTimezone);
   let mentor_utc_offset = timezone.utcOffset();

   timezone.tz(menteeTimezone);
   let mentee_utc_offset = timezone.utcOffset();

   let diffInHours = (mentor_utc_offset - mentee_utc_offset)/60;

   return {
      timeMentor: MENTOR_DATE_AND_TIME.format('h:mm A'),
      offset: diffInHours
   }
}