import React, { Component } from 'react'

import Money from './Money';


class ShowPrice extends Component {

    render() {

        const { pricePerHour, currency } = this.props

        const value = pricePerHour / 1
        return (
            <React.Fragment>
                {
                    pricePerHour > 0 ?
                        <p className="price">
                            <Money value={value} currency={currency} />
                            <small className="trl showPriceComponent hour">/hour</small>
                        </p>
                        :
                        <p className="trl price showPriceComponent free">FREE</p>
                }
            </React.Fragment>
        )
    }
}

export default ShowPrice