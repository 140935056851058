import React from 'react';

import classnames from 'classnames';

//SASS
import './sass/Collapsible.scss';


class Collapsible extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      collapsibleStatus: props.open == undefined || props.open ? true : false
    }

    this.toggleCollapsible = this.toggleCollapsible.bind(this);
  }

  toggleCollapsible(e){
   e.preventDefault()

   this.setState({ collapsibleStatus: !this.state.collapsibleStatus })
  }
  
  render() {
      const { children, btnClose } = this.props;
      const { collapsibleStatus } = this.state;

      //Style button
      const btnStyle = {
         transform: "rotate(-180deg)"
      }

      return (
         <div>
            {
               btnClose ?
                  <button  style={collapsibleStatus ? btnStyle : null} onClick={e => this.toggleCollapsible(e)}>{btnClose}</button>
                  :
                  null
            }
            <div className={classnames({
               "Collapsible": true,
               "on": collapsibleStatus ? true : false
            })}>
               {children}
            </div>  
         </div>
      );
  }
}

export default Collapsible;