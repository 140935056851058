import React, { Component } from 'react';

import './sass/MyFavourite.scss';

import defaultPhoto from '../../../assets/default.png';

import { FaRegHeart, FaHeart } from "react-icons/fa";

import StarRatings from '../../../UI_Elements/StarRatings';

//Assets
import { FiGlobe, FiMapPin, FiSmartphone } from "react-icons/fi";

import { Link } from 'react-router-dom'

import REMOVE_FAVOURITE from '../../../inc/REMOVE_FAVOURITE';

import ShowPrice from '../../../inc/ShowPrice'


class MyFavourite extends Component{

   render(){

      const { listingFavourite } = this.props;

      return(
         <div className="MyFavorite">
            <div className="info">
               <span style={{backgroundImage: `url("${listingFavourite.user.userPhoto.trim() != '' ? listingFavourite.user.userPhoto : defaultPhoto}")`}} className="photo"></span>
               <ShowPrice pricePerHour={listingFavourite.pricePerHour} currency={listingFavourite.currency} />

               <span onClick={() => REMOVE_FAVOURITE(listingFavourite.slug)} className="favorite"><FaHeart size="18" /></span>
            </div>
            <div>
               <h4>{listingFavourite.title}</h4>

               <p className="name">{listingFavourite.user.name} {listingFavourite.user.surname}</p>
               {
                  listingFavourite.reviews.length > 0 ?
                     <StarRatings rating={listingFavourite.rating / listingFavourite.reviews.length} />
                     :
                     <small><i>Have not a review yet</i></small>
               }

               <ul>
                  <li><FiMapPin size="15" /> {listingFavourite.location}</li>
                  <li><FiGlobe size="15" /> {listingFavourite.languages.join(', ')}</li>
                  <li><FiSmartphone size="17" /> {listingFavourite.connectionPreferences.join(', ')}</li>
               </ul>

               <Link to={`/listing/${listingFavourite.slug}`}><button className="btn white">view listing</button></Link>
            </div>
         </div>
      )
   }
}

export default MyFavourite;