import React, { Component } from 'react'

//Components
import MentoryBox from '../../UI_Elements/MentoryBox'
import Response from '../../UI_Elements/Response'
import showResponse from '../../UI_Elements/Response/showResponse'
import Input from '../../UI_Elements/Form/Input'
import Preloader from '../../UI_Elements/Preloader'

import axios from 'axios'
import ResetPasswordByEmail from './reset-password-by-email'

//Redux
import { connect } from 'react-redux'


class ChangePassword extends Component {
    constructor() {
        super()

        this.state = {
            updatePasswordSent: false,
        }

        this.UPDATE_PASSWORD = this.UPDATE_PASSWORD.bind(this);

        //Update password
        this.oldPassword = React.createRef();
        this.confirmPassword = React.createRef();
        this.newPassword = React.createRef();
    }

    UPDATE_PASSWORD(e) {
        e.preventDefault();

        this.setState({ updatePasswordSent: true })

        const oldPassword = this.oldPassword.current.state.inputValue;
        const newPassword = this.newPassword.current.state.inputValue;
        const confirmPassword = this.confirmPassword.current.state.inputValue;

        axios.post('/api/update-password', { oldPassword, newPassword, confirmPassword })
            .then(res => {
                console.log(res)

                //Store.dispatch( UPDATE_USER(res.data) );

                showResponse('updatePasswordSuccess');
                this.setState({ updatePasswordSent: false })
            })
            .catch(err => {
                console.log(err);

                showResponse('updatePasswordError', err.response.data);
                this.setState({ updatePasswordSent: false })
            })
    }

    render() {

        const { updatePasswordSent } = this.state

        return (
            <React.Fragment>
                <Response
                    id="updatePasswordError"
                    type="error"
                    title="Password not updated"
                    description=""
                    btnText="Close"
                    btnAction="close"
                />
                <Response
                    id="updatePasswordSuccess"
                    type="success"
                    title="Password updated"
                    description="Your password was successfully updated"
                    btnText="Close"
                    btnAction="reload"
                />

                <Response
                    id="resetPasswordByEmailSuccess"
                    type="success"
                    title="Reset password by email"
                    description="You will receive an email soon with the steps to reset your password"
                    btnText="Close"
                    btnAction="close"
                />
                <Response
                    id="resetPasswordByEmailError"
                    type="error"
                    title="Something went wrong"
                    description=""
                    btnText="Close"
                    btnAction="close"
                />

                <article id="changePassword">
                    <MentoryBox title="Change password">

                        <form onSubmit={this.UPDATE_PASSWORD} className="simple">
                            <Input type="password" ref={this.oldPassword} label="Old password" />
                            <Input type="password" ref={this.newPassword} label="New password" />
                            <Input type="password" ref={this.confirmPassword} label="Confirm new password" />
                            {
                                updatePasswordSent ?
                                    <Preloader />
                                    :
                                    <button className="btn white trl settings changePassword btnUpdatePassword">update password</button>
                            }
                            <p className="textSecondary"><span className="trl settings changePassword resetPassword step1">Reset your password by email clicking</span> <span className="trl settings changePassword resetPassword step2" onClick={() => ResetPasswordByEmail('resetPasswordByEmailSuccess', 'resetPasswordByEmailError', this.props.user.email)}>here</span></p>
                        </form>
                    </MentoryBox>
                </article>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return state;
}
export default connect(mapStateToProps)(ChangePassword)