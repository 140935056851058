import React, { Component } from 'react'

//Assets
import TransferWiseLogo from '../../assets/settings/transferwise.svg'
import { MdInfoOutline } from "react-icons/md"
import defaultCard from '../../assets/cards/null.svg'

//Cards
import MasterCard from '../../assets/cards/mastercard.svg'
import Visa from '../../assets/cards/visa.svg'
import AMEX from '../../assets/cards/amex.svg'
import Diners from '../../assets/cards/diners.svg'
import Discover from '../../assets/cards/discover.svg'
import JCB from '../../assets/cards/jcb.svg'

//Components
import MentoryBox from '../../UI_Elements/MentoryBox'
import Modal from '../../UI_Elements/Modal'
import Card from '../../components/Card'
import Response from '../../UI_Elements/Response';
import showResponse from '../../UI_Elements/Response/showResponse'
import Preloader from '../../UI_Elements/Preloader'

//Redux
import { connect } from 'react-redux'
import Store from '../../redux/store'
import { UPDATE_CARDS } from '../../redux/actions/'

import Money from '../../inc/Money'
import moment from 'moment-timezone'
import axios from 'axios'


class Billing extends Component {

    constructor() {
        super()

        this.state = {
            //Stripe fields
            cardNumber: null,
            cardExpery: null,
            cardCvc: null,
            brand: defaultCard,
            validationStripe: { cardExpery: '', cardNumber: '', cardCvc: '' },
            statusSend: false,

            balanceMentor: 0,

            chargeHistory: null,

            stripe: null
        }

        this.addPaymentMethodModal = React.createRef();

        //New cards
        this.cardNumber = React.createRef();
        this.dueDate = React.createRef();
        this.cvc = React.createRef();

        this.setBrandIcon = this.setBrandIcon.bind(this);
        this.addNewCard = this.addNewCard.bind(this);

        this.upgrade = this.upgrade.bind(this)
        this.cancelUpgrade = this.cancelUpgrade.bind(this)

    }


    setBrandIcon(brand) {
        //Card type
        var cardType = brand;

        if (cardType.toUpperCase() == 'VISA') {
            cardType = Visa;
        } else if (cardType.toUpperCase() == 'MASTERCARD') {
            cardType = MasterCard;
        } else if (cardType.toUpperCase() == 'DISCOVER') {
            cardType = Discover;
        } else if (cardType.toUpperCase() == 'DINERS') {
            cardType = Diners;
        } else if (cardType.toUpperCase() == 'JCB') {
            cardType = JCB;
        } else if (cardType.toUpperCase() == 'AMEX') {
            cardType = AMEX;
        } else {
            cardType = defaultCard;
        }

        this.setState({ brand: cardType })
    }

    _StripeSetFields() {
        const { validationStripe } = this.state;

        var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        var elements = stripe.elements();

        //Create fields
        var cardNumber = elements.create('cardNumber')
        var cardExpery = elements.create('cardExpiry')
        var cardCvc = elements.create('cardCvc')

        cardNumber.mount('#cardNumber');
        cardExpery.mount('#cardExpery');
        cardCvc.mount('#cardCvc');

        this.setState({ cardNumber, cardExpery, cardCvc, stripe })

        let thisReact = this;

        cardNumber.on('change', function (event) {
            // Switch brand logo
            if (event.brand) {
                thisReact.setBrandIcon(event.brand);
            }

            //Check values
            if (event.error) {
                validationStripe.cardNumber = event.error.message
                thisReact.setState({ validationStripe })
            } else {
                validationStripe.cardNumber = ''
                thisReact.setState({ validationStripe })
            }

        });
        cardExpery.on('change', function (event) {
            //Check values
            if (event.error) {
                validationStripe.cardExpery = event.error.message
                thisReact.setState({ validationStripe })
            } else {
                validationStripe.cardExpery = ''
                thisReact.setState({ validationStripe })
            }
        });
        cardCvc.on('change', function (event) {
            //Check values
            if (event.error) {
                validationStripe.cardCvc = event.error.message
                thisReact.setState({ validationStripe })
            } else {
                validationStripe.cardCvc = ''
                thisReact.setState({ validationStripe })
            }
        });
    }

    addNewCard(e) {
        e.preventDefault();
        this.setState({ statusSend: true })

        const { stripe, cardNumber } = this.state;


        const thisReact = this;
        stripe.createToken(cardNumber)
            .then(function (result) {
                if (result.error) {
                    thisReact.setState({ statusSend: false });
                    return showResponse('AddNewCardError', result.error.message, 'Card declined')
                }
                if (!result.token) thisReact.setState({ statusSend: false })

                axios.put('/api/manage-card', { stripeToken: result.token })
                    .then(res => {

                        Store.dispatch(UPDATE_CARDS(res.data));

                        thisReact.setState({ statusSend: false });
                        thisReact.addPaymentMethodModal.current._openCloseModal(false)
                    })
                    .catch(err => {
                        let code = err.response.data.error.code;
                        let message = err.response.data.error.message;

                        console.log( err.response )

                        showResponse('AddNewCardError', message, 'Card declined')
                        thisReact.setState({ statusSend: false });
                    })

            });
    }


    upgrade() {
        if (window.confirm('We will charge €19,99 in your default card. Would you like to continue?')) {
            this.setState({ statusSend: true })

            axios.post('/api/subscription')
                .then(res => {
                    showResponse('subscriptionSuccess', 'Your subscription start now', 'Thank you for subscribe :)')
                    this.setState({ statusSend: false })
                })
                .catch(err => {
                    showResponse('subscriptionError', err.response.data, 'Ops! Something went wrong')
                    this.setState({ statusSend: false })
                })
        }
    }
    cancelUpgrade() {
        if (window.confirm('We cancel your plan now, would you like to continue?')) {
            this.setState({ statusSend: true })

            axios.post('/api/cancel-subscription')
                .then(res => {
                    showResponse('subscriptionSuccess', res.data, 'We are sorry to see you go ):')
                    this.setState({ statusSend: false })
                })
                .catch(err => {
                    showResponse('subscriptionError', err.response.data, 'Ops! Something went wrong')
                    this.setState({ statusSend: false })
                })
        }
    }


    componentDidMount() {
        //Get history charge from Stripe
        axios.get('/api/charge-history')
            .then(res => {

                //Get balance Mentor
                var balanceMentor = 0;
                this.props.user.mySessions.asMentor.forEach(session => {
                    if (!session.mentorPaid && session.status == 'completed') {
                        if (session.price.mentor) balanceMentor += session.price.mentor;
                    }
                })

                this.setState({ chargeHistory: res.data, balanceMentor })
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {

        //Errors
        const {
            brand,
            validationStripe,
            statusSend } = this.state;

        const { user, settings } = this.props;


        let validUserType
        if (settings.currentUsername == user.username) {
            validUserType = settings.userType
        } else {
            validUserType = 'Mentee'
        }

        console.log(user)


        return (

            <article id="billing">
                <Response
                    id="subscriptionError"
                    type="error"
                    title=""
                    description=""
                    btnText="Close"
                    btnAction="close"
                />
                <Response
                    id="subscriptionSuccess"
                    type="success"
                    title=""
                    description=""
                    btnText="Close"
                    btnAction="close"
                />

                {
                    validUserType == 'Mentor' ?
                        <MentoryBox className="mentor" title="Payouts">

                            <div className="balance">
                                <p className="textPrimary"><strong className="trl settings billing asMentor balance">Your balance is:</strong> <span><Money value={(this.state.balanceMentor / 100)} currency={this.props.user.currency} /></span></p>
                                <p className="textSecondary trl settings billing asMentor balanceExplanation">Balances are paid on the 15th and 30th each month. When we send you your payment, the balance will be reset to 0</p>
                            </div>

                            <form className="simple paymentMethod">
                                <p className="textPrimary trl settings billing asMentor paymentMethod">Payment Method: Deposit to Bank Account</p>
                                <p className="textSecondary"><span className="trl settings billing asMentor details">You'll receive an email from TransferWise after your first payout to confirm your bank details</span>. <a className="primaryLink trl settings billing asMentor moreInfo" href="https://transferwise.com/help/11/getting-started/2932105/can-i-send-money-to-someone-with-only-their-email-address" target="_blank">More info.</a></p>

                                <div>
                                    <img src={TransferWiseLogo} alt="TransferWise" />
                                    <p><strong className="trl settings billing asMentor transferWise">TransferWise (Deposit to bank account)</strong><br /><span className="trl settings billing asMentor default">Default</span></p>
                                </div>

                                <ul>
                                    <li><strong><MdInfoOutline size="17" /> <span className="trl settings billing asMentor important">Important:</span></strong></li>
                                    <li className="trl settings billing asMentor l1">Payouts should arrive in 1-4 business days.</li>
                                    <li className="trl settings billing asMentor l2">Your payouts will be made to your local bank account in your listing’s currency.</li>
                                    <li><span className="trl settings billing asMentor l3">TransferWise supports a limited number of currencies. For USD, TransferWise only supports sending to their borderless accounts (more info</span> <a target="_blank" className="primaryLink trl settings billing asMentor l3Link" href="https://transferwise.com/gb/borderless/">here</a>).</li>
                                    <li className="trl settings billing asMentor l4">TransferWise fee not included in your payment. </li>
                                    <li className="trl settings billing asMentor l5">We will send your exact payout amount (EUR) to TransferWise. Please note that mentors will absorb any currency conversion and TransferWise fees.</li>
                                    <li className="trl settings billing asMentor l6">Transfers to USD borderless will incur a USD $1.13 fee per payout. For all other currencies, please see TransferWise’s pricing page for more information.</li>
                                </ul>

                            </form>

                            <div className="transactionsHistory">
                                <p className="textPrimary trl settings billing asMentor transactionsHistory">Transactions history</p>
                                <p className="textSecondary trl settings billing asMentor downloadInvoices">Download invoices for your own records or to file expense reports with your employer.</p>

                                <table>
                                    <tr className="head">
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Invoice</th>
                                    </tr>
                                </table>
                            </div>
                        </MentoryBox>
                        :
                        <MentoryBox className="mentee" title="Payment methods">
                            <div id="subscription">
                                <p className="textPrimary"><strong className="trl settings billing asMentor subscription">Subscription status</strong></p>
                                <div>
                                    <p>
                                        <span className="status"></span>
                                        <span className="name">
                                            {
                                                user.subscription && user.subscription.active ?
                                                    <React.Fragment>{user.subscription.title}</React.Fragment>
                                                    :
                                                    <React.Fragment>Free Plan active <span>(Free)</span></React.Fragment>
                                            }
                                        </span>
                                    </p>

                                    {
                                        user.subscription && user.subscription.cancel_at_period_end ?
                                            null
                                            :
                                            statusSend ?
                                                <Preloader />
                                                :
                                                <React.Fragment>
                                                    {
                                                        user.subscription && user.subscription.active ?
                                                            <button onClick={this.cancelUpgrade} className="btn white">cancel subscription</button>
                                                            :
                                                            <button onClick={this.upgrade} className="btn white">upgrade</button>
                                                    }
                                                </React.Fragment>
                                    }

                                </div>
                            </div>

                            <p className="textSecondary trl settings billing asMentee description">This is used to pay for your mentoring sessions through our secure payment system.</p>
              
                            <div className="ManageCards">
                                {
                                    user.cards && user.cards.length > 0 ?
                                        user.cards.map(card => {
                                            return <Card brand={card.brand} cardNumber={card.last4} defaultPaymentMethod={card.defaultCard} cardID={card.id} />
                                        })
                                        :
                                        <p style={{ fontSize: '12px' }} className="textSecondary trl settings billing asMentee notPaymentMethod">You have not payment method saved yet</p>
                                }


                                <button onClick={() => {
                                    this.addPaymentMethodModal.current._openCloseModal(true)
                                    this._StripeSetFields()
                                }} className="btn white trl settings billing asMentee btnPaymentMethod">+ payment method</button>
                            </div>

                            <div className="transactionsHistory">
                                <p className="textPrimary trl settings billing asMentee transactions History">Transactions history</p>
                                <p className="textSecondary trl settings billing asMentee downloadInvoices">Download invoices for your own records or to file expense reports with your employer.</p>

                                <table>
                                    <tr className="head">
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Invoice</th>
                                    </tr>
                                    {
                                        this.state.chargeHistory ?
                                            this.state.chargeHistory.data.map((charge, i) => {
                                                return <tr>
                                                    <td>{i}</td>
                                                    <td>{moment(new Date(charge.created * 1000)).format("MM/DD/YY")}</td>
                                                    <td><Money value={(charge.amount / 100)} currency={charge.currency} /></td>
                                                    <td><a href={charge.receipt_url} target="_blank">Download</a></td>
                                                </tr>
                                            })
                                            :
                                            null
                                    }
                                </table>
                            </div>
                        </MentoryBox>
                }



                <Response
                    id="AddNewCardError"
                    type="error"
                    title=""
                    description=""
                    btnText="Try again"
                    btnAction="close"
                />
                <Modal title="Add new payment method" ref={this.addPaymentMethodModal} className="addPaymentMethodModal" iconToClose>
                    <form id="paymenMethodForm" onSubmit={this.addNewCard}>

                        <div>
                            <label htmlFor="cardNumber">Card number*</label>
                            <div id="cardNumber"></div>
                            <span className="brand"><img src={brand} alt="Brand" /></span>
                        </div>
                        <div>
                            <label htmlFor="cardExpery">Due date*</label>
                            <div id="cardExpery"></div>
                        </div>
                        <div>
                            <label htmlFor="cardCvc">CVC*</label>
                            <div id="cardCvc"></div>
                        </div>
                        <p className="alert">
                            {
                                validationStripe.cardNumber.trim() != '' ?
                                    <React.Fragment>{validationStripe.cardNumber}*<br /></React.Fragment>
                                    : ''
                            }
                            {
                                validationStripe.cardExpery.trim() != '' ?
                                    <React.Fragment>{validationStripe.cardExpery}*<br /></React.Fragment>
                                    : ''
                            }
                            {
                                validationStripe.cardCvc.trim() != '' ?
                                    <React.Fragment>{validationStripe.cardCvc}*<br /></React.Fragment>
                                    : ''
                            }
                        </p>
                        {
                            statusSend ?
                                <Preloader />
                                :
                                <button type="submit" className="btn">add new card</button>
                        }
                    </form>
                </Modal>

            </article>
        )
    }
}


const mapStateToProps = state => {
    return state;
}
export default connect(mapStateToProps)(Billing)