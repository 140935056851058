import React from 'react';

//Assets
import { IoIosArrowDown } from "react-icons/io";

import classnames from 'classnames';

class Dropdown extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
         dropdownValue: props.value,
         dropdownOpened: false,
         dropdownTop: 0
      }

      this.toggleDropdown = this.toggleDropdown.bind(this);
      this.selectValue = this.selectValue.bind(this);
      this.handleClick = this.handleClick.bind(this);

      this.node = React.createRef();
  }

  toggleDropdown(){
      //
      let heightNode = this.node.clientHeight;
      let SMALL_node = this.node.childNodes[2].clientHeight;

      this.setState({dropdownOpened: !this.state.dropdownOpened, dropdownTop: (heightNode - SMALL_node)})
  }
  selectValue(e){
      this.setState({dropdownValue: e.target.innerText, dropdownOpened: false})

      if( this.props.onChange ){
         this.props.onChange(e.target.innerText);
      }
  }

  componentWillMount(){
      document.addEventListener('mousedown', this.handleClick, false)
  }
  componentWillUnmount(){
      document.removeEventListener('mousedown', this.handleClick, false)
  }
  handleClick(e){
     if( this.node.contains(e.target) ) return;

     this.setState({dropdownOpened: false})
  }

  preventWrite(e){
     e.preventDefault();
     return false;
  }
 
  render() {

   const { label, placeholder, values, description, error, className } = this.props;
   const { dropdownValue, dropdownOpened, dropdownTop } = this.state;

   return (
      <div ref={node => this.node = node} className={classnames({
         "Dropdown": true,
         [className]: className
      })}>
         {
            label ?
               <label className={label ? `trl label ${window.TranslateApp.clearString(label)}` : ''}>{label}</label>
               :
               null
         }
         
         <p onClick={this.toggleDropdown} className="flex">
            <input  
               onKeyDown={this.preventWrite}
               onKeyPress={this.preventWrite}
               onKeyUp={this.preventWrite}
               value={dropdownValue} 
               placeholder={placeholder} 
               type="text"
            />
            <IoIosArrowDown />
         </p>
         {
            description ?
               <small className={`trl description ${window.TranslateApp.clearString(description)}`}>{description}</small>
               :
               <small></small>
         }
         {
            error ?
               <span className={`form error trl error ${window.TranslateApp.clearString(error)}`}>{error}</span>
               :
               null
         }
         
         <ul style={{top: dropdownTop}} className={dropdownOpened ? 'opened' : ''}>
            {values.map(value => {
               return <li onClick={(e) => this.selectValue(e)} key={value}>{value}</li>
            })}
         </ul>
      </div>
   );
  }
}

export default Dropdown;