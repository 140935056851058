import React, { Component } from 'react';

import './sass/MentoryBox.scss';

import classnames from 'classnames';

class MentoryBox extends Component{

   render(){

      const { children, title, className, id } = this.props;

      return(
         <div className={classnames({
            'MentoryBox': true,
            [className]: className ? true : false
         })} id={ id ? id : null }>
            <p className={`trl mentoryBox title ${window.TranslateApp.clearString(title)}`}>{title}</p>

            <div className="container">
               {children}
            </div>
         </div>
      )
   }
}

export default MentoryBox;