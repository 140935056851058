import React, { Component } from 'react';

import './sass/CreateEditListing.scss';

//Components
import Nav from '../../components/Menu';
import Input from '../../UI_Elements/Form/Input';
import Footer from '../../components/Footer';
import RadioButtom from '../../UI_Elements/Form/RadioButton';
import InputTag from '../../UI_Elements/Form/InputTag';
import EditorMentory from '../../UI_Elements/Form/WysiwygEditor';
import MentoryBox from '../../UI_Elements/MentoryBox';

//Assets
import { IoIosArrowDown } from "react-icons/io";
import cameraImg from '../../assets/camera.svg'

import Money from '../../inc/Money';

//Axios
import axios from 'axios';

//Response
import Response from '../../UI_Elements/Response';
import showResponse from '../../UI_Elements/Response/showResponse';

//Redux
import Store from '../../redux/store';
import { connect } from 'react-redux';
import { UPDATE_USER_VALUE } from '../../redux/actions';

import Location from '../../UI_Elements/Form/Location';

import Preloader from '../../UI_Elements/Preloader';

import SelectCurrencyModal from '../../components/SelectCurrencyModal';

import { Link } from 'react-router-dom'

import classnames from 'classnames'


//Actions
import uploadImageFunction from './actions/uploadImage'
import selectServiceFunction from './actions/selectService'
import changeListingTypeFunction from './actions/changeListingType'
import pricePerHourFilterFunction from './actions/pricePerHourFilter'

//Validate
import validateValues from './actions/validate/validateValues'
import setError from './actions/validate/setError'
import setFormData from './actions/validate/setFormData'


class CreateListing extends Component {
   constructor(props) {
      super(props)

      this.state = {
         services: props.settings.services,
         listingPaid: false,
         pricePerHour: 0,
         userPhoto: null,

         //Errors
         errorListingTitle: '',
         errorLocation: '',
         errorListingBio: '',
         errorCategories: '',
         errorSkills: '',
         errorLanguage: '',
         errorConnectionPreferences: '',
         errorLinkedinProfile: '',
         errorLengthSession: '',
         errorUserPhoto: '',
         errorServices: '',
         errorPricePerHour: '',

         //sent form?
         sentForm: false,
      }

      this.changeListingType = this.changeListingType.bind(this)
      this.pricePerHourFilter = this.pricePerHourFilter.bind(this)
      this.CREATE_LISTING = this.CREATE_LISTING.bind(this)
      this.uploadImage = this.uploadImage.bind(this)
      this.selectService = this.selectService.bind(this)

      this.pricePerHour = React.createRef();
      this.listingTitle = React.createRef();
      this.location = React.createRef();
      this.listingBio = React.createRef();
      this.photoUser = React.createRef()

      this.languages = React.createRef();
      this.connectionPreferences = React.createRef();
      this.linkedinProfile = React.createRef();
   }

   uploadImage = e => uploadImageFunction(e, this)
   selectService = (checked, index, value) => selectServiceFunction(checked, index, value, this)
   changeListingType = value => changeListingTypeFunction(value, this)
   pricePerHourFilter = value => pricePerHourFilterFunction(value, this)

   async CREATE_LISTING(e) {
      e.preventDefault()

      //Validate
      const {
         listingTitleIsValid,
         locationIsValid,
         listingBioIsValid,
         languageIsValid,
         connectionPreferencesIsValid,
         linkedinProfileIsValid,
         servicesIsValid,
         userPhotoIsValid,
         pricePerHourIsValid
      } = validateValues(this)

      setError({
         listingTitleIsValid,
         locationIsValid,
         listingBioIsValid,
         languageIsValid,
         connectionPreferencesIsValid,
         linkedinProfileIsValid,
         servicesIsValid,
         userPhotoIsValid,
         pricePerHourIsValid
      },
         {
            listingTitleIsValid: 1,
            locationIsValid: 1,
            listingBioIsValid: 1,
            languageIsValid: 1,
            connectionPreferencesIsValid: 1,
            linkedinProfileIsValid: 1,
            servicesIsValid: 1,
            userPhotoIsValid: 1,
            pricePerHourIsValid: 1
         }, this)


      let formData = setFormData(this)


      this.setState({ sentForm: true })

      if (listingTitleIsValid &&
         locationIsValid &&
         listingBioIsValid &&
         languageIsValid &&
         connectionPreferencesIsValid &&
         linkedinProfileIsValid &&
         userPhotoIsValid &&
         servicesIsValid &&
         pricePerHourIsValid) {

         await axios.post('/api/create-listing', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(res => {
               this.setState({ sentForm: false })

               Store.dispatch(UPDATE_USER_VALUE("myListings", res.data, 'ADD'));
               return showResponse("createListingSuccessResponse");
            })
            .catch(err => {
               this.setState({ sentForm: false })
               console.log("err -> ", err)
               if (err.response) return showResponse("listingErrorServerResponse", err.response.data);
            })

      } else {
         this.setState({ sentForm: false })
         showResponse("listingErrorResponse");
      }
   }

   componentDidMount() {
      //Check if current user is connected with linkedin
      if (!this.props.user.linkedinConnected) {
         showResponse("listingConnectWithLinkedin", "Firstly you need connected with Google to create a listing");
      }
      if (!this.props.user.myListings.length == 0) {
         showResponse("createOneListing", "You can only create one listing at a time. Multiple listing options coming soon.");
      }
   }

   render() {

      const { services, listingPaid, pricePerHour, userPhoto } = this.state;
      const { user } = this.props

      //Errors
      const { errorListingTitle, errorLocation, errorListingBio, errorLanguage, errorConnectionPreferences, errorPricePerHour, errorLinkedinProfile, errorUserPhoto, errorServices } = this.state;

      const servicesRedux = this.props.settings.services

      return (
         <div id="CreateListing">
            <Nav style="stylish" />

            {/* Responses */}
            <Response
               id="listingErrorResponse"
               type="error"
               title="Some values are not valid"
               description="Please review the entered values"
               btnText="Close"
               btnAction="close"
            />
            <Response
               id="listingErrorServerResponse"
               type="error"
               title="Oh no! You can’t do this."
               description=""
               btnText="Close"
               btnAction="close"
            />
            <Response
               id="createOneListing"
               type="error"
               title="Oh no! You can’t do this."
               description=""
               btnText="Back to home"
               btnAction="/"
            />
            <Response
               id="createListingSuccessResponse"
               type="success"
               title="Your listing was successfully submitted"
               description="We'll review it and publish it soon if it meets our guidelines."
               btnText="go to profile"
               btnAction="/my-listings"
            />


            {/* Linkedin Error */}
            <Response
               id="listingConnectWithLinkedin"
               type="error"
               title="Connect with Google"
               description=""
               btnText="Become a mentor"
               btnAction="/become-a-mentor"
            />
            <SelectCurrencyModal />


            <section id="createAndEditListingContainer">
               <div className="content">

                  <h1 className="trl createListing title">Create listing</h1>
                  <p className="trl createListing subtitle">Make money by helping others to grow.</p>

                  <form className="simple smart" onSubmit={this.CREATE_LISTING}>

                     <MentoryBox title="Create listing" className="createListingBox">
                        <div className="uploadPhoto">
                           <div>
                              <label className="trl createListing listingPicture">Listing picture</label>
                              <p className="trl createListing weRecommend">We recommend using a front facing picture.</p>
                              <span style={{ backgroundImage: `url("${userPhoto ? userPhoto.url : user.userPhoto}")` }} onClick={() => this.photoUser.current.click()} className="photo">
                                 <input onChange={e => this.uploadImage(e)} ref={this.photoUser} type="file" />
                                 {
                                    !userPhoto && user.userPhoto.trim() == '' ?
                                       <img src={cameraImg} />
                                       :
                                       null
                                 }
                              </span>
                              <span className="error">{errorUserPhoto}</span>
                           </div>
                        </div>
                        <div>
                           <Input id="listingTitle" ref={this.listingTitle} error={errorListingTitle} placeholder="Ejemplo: Recruiter en LinkedIn, Coach de liderazgo..." label="Listing title" />
                           <Location id="whereAreYouBased" ref={this.location} error={errorLocation} placeholder="City, Country" label="Where are you based?" ref={this.location} />
                        </div>
                        <div className="EditorMentory">
                           <EditorMentory ref={this.listingBio} error={errorListingBio} label="What can you help with?" />
                        </div>
                        <div>
                           <InputTag ref={this.languages} error={errorLanguage} tags={this.props.settings.languagesName} tagsSelected={["Spanish"]} label="Languages that you are fluent at" icon={<IoIosArrowDown />} />
                           <InputTag ref={this.connectionPreferences} error={errorConnectionPreferences} tags={this.props.settings.connectionPreferences} tagsSelected={[]} label="Connection preferences" icon={<IoIosArrowDown />} />
                        </div>

                        <div>
                           <Input id="linkedinProfile" ref={this.linkedinProfile} error={errorLinkedinProfile} label="LinkedIn profile" />
                        </div>

                        <br />

                        <div>
                           <RadioButtom trl type="h" onChange={value => this.changeListingType(value)} title="Is this a free or paid listing?" checked="1" labels={["Paid", "Free"]} values={["Paid", "Free"]} />

                           {
                              listingPaid ?
                                 <div className="paid">
                                    <Input error={errorPricePerHour} id="pricePerHour" validateOnlyNumbers placeholder="50" maxLength="4" onChange={value => this.pricePerHourFilter(value)} ref={this.pricePerHour} label="Price per hour" />
                                 </div>
                                 :
                                 null
                           }
                        </div>

                        <div className="servicesOffered">
                           <div>
                              <p><span className="trl title servicesOffered">Services offered</span></p>

                              <article className="trlGlobal">
                                 {
                                    servicesRedux.map((s, i) => {
                                       return <div key={i} style={{ background: `${i % 2 == 0 ? '#F6F5FA' : '#FFF'}` }}>
                                          <div className="switch">
                                             <label>
                                                <input onChange={e => this.selectService(e.target.checked, i)} type="checkbox" />
                                                <span className="lever"></span>
                                                <span className="label">{s.title}</span>
                                             </label>
                                          </div>

                                          <p className="service">
                                             <span className={`trl global service title ${s.id}`}>{s.title}</span>
                                             <span className={`trl global service desc ${s.id}`}>{s.desc}</span>
                                          </p>

                                          <div className={classnames({
                                             'boxes': true,
                                             'disabled': services[i].active ? false : true
                                          })}>
                                             <div className={services[i].selected ? services[i].selected.includes(60) ? 'active' : '' : ''} onClick={() => this.selectService(null, i, 60)}><span>60 min<br />{listingPaid ? <Money value={pricePerHour} currency={this.props.user.currency} /> : <span className="trl createListing free">Free</span>}</span></div>
                                             <div className={services[i].selected ? services[i].selected.includes(30) ? 'active' : '' : ''} onClick={() => this.selectService(null, i, 30)}><span>30 min<br />{listingPaid ? <Money value={pricePerHour / 2} currency={this.props.user.currency} /> : <span className="trl createListing free">Free</span>}</span></div>
                                          </div>
                                       </div>
                                    })
                                 }
                              </article>
                              {
                                 errorServices.trim() != '' &&
                                 <span className="trl createListing errorServices error">{errorServices}</span>
                              }
                           </div>
                        </div>

                     </MentoryBox>

                     <div className="actions flex">
                        {
                           this.state.sentForm ?
                              <Preloader />
                              :
                              <button className="btn trl createListing submitListing">submit listing</button>
                        }

                        <p><Link className="trl createListing cancel" to="/become-a-mentor">cancel</Link></p>
                     </div>
                  </form>

               </div>
            </section>

            <Footer />

         </div>
      )
   }
}

const mapStateToProps = state => {
   return state;
}
export default connect(mapStateToProps)(CreateListing);