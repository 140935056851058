import React, { Component } from 'react';

import './sass/CardPublicProfile.scss';

import StarRatings from '../../UI_Elements/StarRatings';

//Assets
import { FiGlobe, FiMapPin, FiSmartphone } from "react-icons/fi";

import { withRouter } from "react-router";

import ShowPrice from '../../inc/ShowPrice'

class CardPublicProfile extends Component{

   render(){

      const { listing } = this.props;

      return(
         <div onClick={() => this.props.history.push(`/listing/${listing.slug}`)} className="CardPublicProfile trlGlobal">
            
            <ShowPrice pricePerHour={listing.pricePerHour} currency={listing.currency} />

            <h4>{listing.title}</h4>

            <p className="name">{listing.user.name} {listing.user.surname}</p>
            {
                  listing.reviews.length > 0 ?
                     <StarRatings rating={(listing.rating / listing.reviews.length)} />
                     :
                     <small><i className="trl cardPublicProfile noReviewsYet">No reviews yet</i></small>
               }

            <ul>
               <li><FiMapPin size="15" /> {listing.location}</li>
               <li><FiGlobe size="15" /> {listing.languages.join(', ')}</li>
               <li><FiSmartphone size="17" /> {listing.connectionPreferences.join(', ')}</li>
            </ul>
         </div>
      )
   }
}

export default withRouter(CardPublicProfile);