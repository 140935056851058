import React, { Component } from 'react';

import Input from '../../UI_Elements/Form/Input';

import './sass/ResetPassword.scss';

import { withRouter } from "react-router";
import { Link } from "react-router-dom";

//Assets
import { MdClose } from "react-icons/md";

import ResetPasswordByEmail from '../Settings/reset-password-by-email';

import Response from '../../UI_Elements/Response'
import showResponse from '../../UI_Elements/Response/showResponse'

import Preloader from '../../UI_Elements/Preloader';

import axios from 'axios';


class ResetPassword extends Component{

   constructor(props){
      super(props)

      this.state = {
         formSent: false
      }

      this.email = React.createRef();

      this.SENT_EMAIL_TO_RESET_PASSWORD = this.SENT_EMAIL_TO_RESET_PASSWORD.bind(this);
   }

   SENT_EMAIL_TO_RESET_PASSWORD(e){
      e.preventDefault();

      this.setState({formSent: true});

      axios.post(`/api/reset-password`, {email: this.email.current.state.inputValue})
         .then(res => {
            console.log(res)

            this.setState({formSent: false});
            showResponse('resetPasswordByEmailSuccess');
         })
         .catch(err => {
            console.log(err)

            this.setState({formSent: false});
            showResponse('resetPasswordByEmailError', err.response.data);
         })
   }

   render(){

      const { formSent } = this.state;

      return(
         <div id="ResetPassword">
            <Response 
               id="resetPasswordByEmailSuccess"
               type="success"
               title="Reset password by email"
               description="You will receive an email soon with the steps to reset your password"
               btnText="Go to home"
               btnAction="/"
            />
            <Response 
               id="resetPasswordByEmailError"
               type="error"
               title="Something went wrong"
               description=""
               btnText="Close"
               btnAction="close"
            />

            <section>
               <div className="content">

                  <Link className="back" to="/"><MdClose size="35" /></Link>

                  <div>
                     <h2>Forgot your password?</h2>
                     <p className="subtitle">Enter your username or email below and we’ll send you reset instructions.</p>

                     <form onSubmit={this.SENT_EMAIL_TO_RESET_PASSWORD} className="simple" action="">
                        <Input ref={this.email} placeholder="Email" />
                        {
                           formSent ?
                              <Preloader />
                              :
                              <button className="btn large">Recover your password</button>
                        }
                     </form>
                  </div>

               </div>
            </section>
         </div>
      )
   }
}

export default withRouter(ResetPassword);