import React, { Component } from 'react';

import './sass/Review.scss';

import defaultPhoto from '../../assets/default.png';
import StarRatings from '../../UI_Elements/StarRatings';

class Review extends Component{

   render(){

      const { review } = this.props;

      return(
         <div className="Review">
            <span style={{backgroundImage: `url(${review.author.photo.trim() != '' ? review.author.photo : defaultPhoto})`}} className="photo"></span>
            <div>
               <p className="name">{review.author.name} {review.author.surname}</p>
               <StarRatings rating={review.rate} />
               <p className="feedback">{review.comments}</p>
            </div>
         </div>
      )
   }
}

export default Review;