import React, { Component } from 'react'
import axios from 'axios'

import Preloader from '../../UI_Elements/Preloader'
import Slider from '../../UI_Elements/Slider'
import CardMentor from '../../components/CardMentor'

class RelatedMentor extends Component {

    constructor() {
        super()

        this.state = {
            relatedMentor: [],
            relatedMentorLoad: false
        }
    }

    componentDidMount() {
        //Get related Mentors
        axios.post('/api/get-listings', { limit: 6 })
            .then(res => {
                this.setState({ relatedMentor: res.data, relatedMentorLoad: false })
            })
            .catch(err => {
                this.setState({ relatedMentorLoad: false })
            })
    }

    render() {

        const { relatedMentor, relatedMentorLoad } = this.state

        return (
            <div id="RelatedMentorListing">
                {
                    relatedMentor.length > 1 ?
                        <section className="relatedMentors">
                            <div className="content">
                                <h2 className="trl listing relatedMentors title">Related mentors</h2>
                                <p className="trl listing relatedMentors subtitle">Some related mentors that could help.</p>
                                {
                                    relatedMentorLoad ?
                                        <Preloader />
                                        :
                                        <Slider dots>
                                            {
                                                relatedMentor.map((rm, i) => {
                                                    return <CardMentor key={i} info={rm} />
                                                })
                                            }

                                        </Slider>
                                }
                            </div>
                        </section>
                        :
                        null
                }
            </div>
        )
    }
}

export default RelatedMentor