import React, { Component } from 'react';

import './sass/OpenDispute.scss';

import defaultPhoto from '../../assets/default.png';

import Modal from '../../UI_Elements/Modal';
import RadioButtom from '../../UI_Elements/Form/RadioButton';
import Input from '../../UI_Elements/Form/Input';

import Preloader from '../../UI_Elements/Preloader';

import axios from 'axios';

//Redux
import { connect } from 'react-redux';
import Store from '../../redux/store';
import { UPDATE_USER } from '../../redux/actions';

//Response 
import Response from '../../UI_Elements/Response';
import showResponse from '../../UI_Elements/Response/showResponse';



class OpenDispute extends Component{


   constructor(){
      super();

      this.state = {
         sentForm: false,
      }


      this.details = React.createRef();

      this.OPEN_DISPUTE = this.OPEN_DISPUTE.bind(this);
   }

   OPEN_DISPUTE(e){
      e.preventDefault();

      this.setState({sentForm: true})
      
      const usernameReported = this.props.username;
      const id = this.props.id;
      const details = this.details.current.state.inputValue;

      const { refModal } = this.props;

      axios.put('/api/open-dispute', {details, usernameReported, id})
         .then(res => {

            Store.dispatch( UPDATE_USER(res.data) );

            showResponse('openDisputeSuccess', "We will review it and will contact you soon.");
            refModal.current._openCloseModal(false);
            this.setState({sentForm: false})
         })
         .catch(err => {
            console.log(err)
            showResponse('openDisputeError', err.response.data)
            this.setState({sentForm: false})
         })

   }

   render(){

      const { refModal } = this.props;
      const { sentForm } = this.state;

      const { userPhoto, name, surname, username, titleListing } = this.props;


      return(
         <React.Fragment>
            <Response 
               id="openDisputeError"
               type="error"
               title="Something went wrong"
               description=""
               btnText="Close"
               btnAction="close"
            />
            <Response 
               id="openDisputeSuccess"
               type="success"
               title="Dispute opened"
               description=""
               btnText="Close"
               btnAction="close"
            />

            <Modal ref={refModal} title="Open a dispute" className="OpenDispute trlGlobal" iconToClose>
               {
                  name && surname && username && titleListing ?
                     <React.Fragment>
                        <span style={{backgroundImage: `url(${ userPhoto.trim() != '' ? userPhoto : defaultPhoto })`}} className="photo"></span>
                        <h4>{titleListing}</h4>
                        <p className="menteeName">{name} {surname}</p>

                        <form onSubmit={this.OPEN_DISPUTE} className="simple">
                           <p className="trl OpenDispute text">If you’re not able to reach an agreement with your Mentor about an issue with your session, you can file a dispute.</p>
                           <br/>
                           <Input id="openDispute" ref={this.details} textarea label="What happened with your session?" placeholder="Please explain to us the reason for your dispute. The more detailed, the better." />

                           <div className="actions flex">
                              {
                                 sentForm ?
                                    <Preloader />
                                    :
                                    <button className="btn trl OpenDispute sendRequest">Send request</button>
                              }
                              <p className="trl OpenDispute cancel" onClick={() => refModal.current._openCloseModal(false)}>cancel</p>
                           </div>
                        </form>
                     </React.Fragment>
                     :
                     null
               }
            </Modal>
         </React.Fragment>
      )
   }
}

const mapStateToProps = state => {
   return state;
}
export default connect(mapStateToProps)(OpenDispute);